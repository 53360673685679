import axios from "axios";
import { BaseUrl } from "../ApiConfig";

export const getPaymentPlan = async (jwt, contactId) => {
  const request = await axios.get(
    `${BaseUrl}/api/paymentPlan/getContact/${contactId}`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );
  return request;
};
