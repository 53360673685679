import React from "react";
import style from "./elements.module.scss";
import { /* DeleteIcon, */ EditIcon } from "../../assets/icons/index";
import { useTranslation } from "react-i18next";

function PaymentPlanItem({
  setSelectedId,
  paymentPlans,
  setPaymentPlanSetting,
  setChangeButton,
  setFakeInput,
}) {
  const { t } = useTranslation();
  // let projectId = window.location.pathname.split("/")[2];

  return (
    <>
      {paymentPlans
        ? paymentPlans.map((item, index) => {
            console.log(item._id);
            return (
              <div
                key={`payment-plan-${item._id}`}
                className={style.projectsItem}
              >
                <div className={style.title}>{item.name ? item.name : ""}</div>

                <div className={style.footer}>
                  {item.isCash ? (
                    <>
                      <span>
                        <h2>{t("pesinOdememi")}</h2>
                        <h5 style={{ color: "#5dade2" }}>
                          {item.isCash ? " X " : ""}
                        </h5>
                      </span>
                      <span>
                        <h2>{t("pesinOdemeIndirimOrani")}: </h2>
                        <h5 style={{ color: "#5dade2" }}>
                          {item.cashDiscountRate ? item.cashDiscountRate : ""}
                        </h5>
                      </span>
                    </>
                  ) : (
                    <>
                      <span>
                        <h2>{t("taksitSayisi")}: </h2>
                        <h5 style={{ color: "#5dade2" }}>
                          {item.installmentCount ? item.installmentCount : ""}
                        </h5>
                      </span>
                      <span>
                        <h2>{t("pesinOdemeOrani")}: </h2>
                        <h5 style={{ color: "#5dade2" }}>
                          {item.upfrontPaymentRate
                            ? item.upfrontPaymentRate
                            : ""}
                        </h5>
                      </span>

                      <span>
                        <h2>{t("araOdemeTipi")}: </h2>
                        <h5 style={{ color: "#5dade2" }}>
                          {item.specialPaymentType === "percentage"
                            ? t("araOdemeTipiYuzde")
                            : t("araOdemeTipiSabit")}
                        </h5>
                      </span>

                      {item.specialPaymentType === "percentage" ? (
                        <span>
                          <h2>{t("araOdemeOrani")}: </h2>
                          <h5 style={{ color: "#5dade2" }}>
                            {item.specialPaymentRate
                              ? item.specialPaymentRate
                              : ""}
                          </h5>
                        </span>
                      ) : item.specialPaymentType === "fixed" ? (
                        <span>
                          <h2>{t("araOdemeTutari")}: </h2>
                          <h5 style={{ color: "#5dade2" }}>
                            {item.specialPaymentAmount
                              ? item.specialPaymentAmount
                              : ""}
                          </h5>
                        </span>
                      ) : null}
                      {item.specialPaymentType.includes("percentage") ? (
                        <span>
                          <h2>{t("araOdemeSayisi")}: </h2>
                          <h5 style={{ color: "#5dade2" }}>
                            {item.specialPaymentCount
                              ? item.specialPaymentCount
                              : ""}
                          </h5>
                        </span>
                      ) : null}
                    </>
                  )}
                  <div className={style.actionDeleteAndEdit}>
                    <div
                      onClick={() => {
                        setPaymentPlanSetting({
                          name: item.name,
                          isCash: item.isCash,
                          cashDiscountRate: item.cashDiscountRate
                            ? item.cashDiscountRate
                            : "",
                          installmentCount: item.installmentCount,
                          upfrontPaymentRate: item.upfrontPaymentRate,
                          specialPaymentType: item.specialPaymentType,
                          specialPaymentAmount: item.specialPaymentAmount,
                          specialPaymentRate: item.specialPaymentRate,
                          specialPaymentCount: item.specialPaymentCount,
                        });
                        setSelectedId(item._id);
                        setChangeButton(true);
                        setFakeInput(true);
                      }}
                    >
                      <EditIcon />
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        : null}
    </>
  );
}

export default PaymentPlanItem;
