import style from "./subheader.module.scss";
import Popup from "../LeadElements/Popup";
import { useState, useRef, useEffect } from "react";
import { DownIcon, UpIcon } from "../../assets/icons";
import { useSelector } from "react-redux";
import ExcelExportPopup from "./ExcelExportPopup.jsx";
import { NewButton } from "./Buttons";
import IndeterminateCheckbox from "../table/indeterminateCheckbox/IndeterminateCheckbox";

import { useTranslation } from "react-i18next";

const CheckBox = ({ getToggleHideAllColumnsProps, allColumns }) => {
  const [select, setSelect] = useState(false);
  const { t } = useTranslation();
  const checkboxMenu = useRef(null);

  useEffect(() => {
    const closeOpenMenus = (e) => {
      if (
        checkboxMenu.current &&
        select &&
        !checkboxMenu.current.contains(e.target)
      ) {
        setSelect(false);
      }
    };

    document.addEventListener("mousedown", closeOpenMenus);

    return () => {
      document.removeEventListener("mousedown", closeOpenMenus);
    };
  }, [select]);

  return (
    <div className={style.checkboxContact} ref={checkboxMenu}>
      <button className={style.button} onClick={() => setSelect(!select)}>
        {t("kolonSeciniz")}
        {select ? <UpIcon /> : <DownIcon />}
      </button>
      {select ? (
        <div className={style.select}>
          <div>
            <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} />
            {t("hepsiniSec")}
          </div>
          {allColumns.map((column) => (
            <div key={column.id}>
              <label>
                <input type="checkbox" {...column.getToggleHiddenProps()} />{" "}
                {typeof column.Header === "string" ? column.Header : ""}
              </label>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

const BidMenu = ({
  bidData,
  selectedBid,
  getToggleHideAllColumnsPropsForBids,
  allBidsColumns,
}) => {
  const { t } = useTranslation();
  const [popup, setPopup] = useState(false);
  const user = useSelector((state) => state.user.userData);
  const [excelData, setExcelData] = useState([]);
  const [excelPopup, setExcelPopup] = useState(false);
  const [headers, setHeaders] = useState([]);

  const deleteCustomerByIds = () => {
    // let arrayids = [];
    // contacData.forEach((d) => {
    //   if (d.select) {
    //     arrayids.push(d.id);
    //   }
    // });
    // axios
    //   .delete(
    //     `https://aremas-api-new.herokuapp.com/api/contact/delete/${arrayids}`
    //   )
    //   .then((data) => {
    //     window.location.reload();
    //   })
    //   .catch((err) => alert(err));
  };

  const excelExportAndDownload = (data) => {
    setExcelPopup(true);
    setHeaders([
      t("aktiviteTipi"),
      t("aktiviteIsmi"),
      t("musteriAdi"),
      t("gorusmeKanali"),
      t("iliskiliProje"),
      t("urunTipi"),
      t("malSahibi"),
      t("dovizBirimi"),
      t("kdvHaricSatisFiyat"),
      t("kdvVadelendirilicakTutar"),
      t("kdvOrani"),
      t("kdvDahilSatisTutari"),
      t("indirimOrani"),
      t("kapora"),
      t("pesinat"),
      t("vadelendirilecekTutar"),
      t("bankaKredisi"),
      t("aktiviteTutari"),
      t("kiralamaYil"),
      t("sozlesmeBaslangicTarihi"),
      t("krediKullanilanBanka"),
      t("teslimTarihi"),
      t("odemeTipi"),
      t("birimKiraBedeli"),
      t("kiraArtisi"),
      t("guvenceBedeliAy"),
      t("otopark"),
      t("dekorasyon"),
      t("isletmeGideri"),
      t("aktiviteAsamasi"),
      t("kapanisTarihi"),
      t("basarisizKapanisNedeni"),
      t("not"),
    ]);

    const newData = data.map((item) => {
      return {
        [t("aktiviteTipi")]: item.bidShape,
        [t("aktiviteIsmi")]: item.bidName,
        [t("musteriAdi")]: item.queryContactName,
        [t("gorusmeKanali")]: item.meetingShape,
        [t("iliskiliProje")]: item.projeName,
        [t("urunTipi")]: item.inventoryName,
        [t("malSahibi")]: item.inventoryId.queryOwnerInventoryName,
        [t("dovizBirimi")]: item.currency ? item.currency : "TRY",
        [t("kdvHaricSatisFiyat")]: item.bidAmount,
        [t("kdvVadelendirilicakTutar")]: item.bidAmountWithVat - item.bidAmount,
        [t("kdvDahilSatisTutari")]: item.bidAmountWithVat,
        [t("indirimOrani")]: item.discountRate,
        [t("kapora")]: item.advancePayment,
        [t("pesinat")]: item.downPaymnet,
        [t("vadelendirilecekTutar")]: item.termDownPaymnet,
        [t("bankaKredisi")]: item.bankCredit,
        [t("aktiviteTutari")]: item.bidAmount,
        [t("kiralamaYil")]: item.rentalPeriod,
        [t("sozlesmeBaslangicTarihi")]: item.contractStartDate,
        [t("krediKullanilanBanka")]: item.bankToBeUsed,
        [t("teslimTarihi")]: item.deliveryTime,
        [t("odemeTipi")]: item.paymentType,
        [t("birimKiraBedeli")]: item.unitRentalPrice,
        [t("kiraArtisi")]: item.rentIncrease,
        [t("guvenceBedeliAy")]: item.securityDeposit,
        [t("otopark")]: item.carPark,
        [t("dekorasyon")]: item.decoration,
        [t("isletmeGideri")]: item.operatingExpenses,
        [t("aktiviteAsamasi")]: item.bidStage,
        [t("kapanisTarihi")]: item.kapanisTarihi,
        [t("basarisizKapanisNedeni")]: item.failedStageNote,
        [t("not")]: item.not,
      };
    });
    setExcelData(newData);
  };
  const exelDownloaded = () => {
    setExcelPopup(false);
  };

  return (
    <>
      {popup ? (
        <Popup closeClick={setPopup} successClick={deleteCustomerByIds} />
      ) : null}
      {excelPopup ? (
        <ExcelExportPopup
          closeClick={exelDownloaded}
          headers={headers}
          paddingVal="150px 100px"
          data={excelData}
          fileName="Aktiviteler"
          text={t("excelExportAktivite")}
        />
      ) : null}
      <div className={style.leadMenu}>
        <div className={style.left}>
          <div
            style={{
              marginLeft: "26px",
              width: "100%",
              fontWeight: "600",
              fontSize: "16px",
            }}
          >
            {t("aktiviteler")}
          </div>
        </div>
        <div className={style.right}>
          <CheckBox
            getToggleHideAllColumnsProps={getToggleHideAllColumnsPropsForBids}
            allColumns={allBidsColumns}
          />
          {(user.department === "admin" || user.department === "ceo") && (
            <NewButton
              title={t("veriyiDisaAktar")}
              icon={"exel"}
              onClick={() => {
                excelExportAndDownload(bidData);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default BidMenu;
