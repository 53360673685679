import React, { useState } from "react";
import style from "./elements.module.scss";
import { EditIcon, DownloadFileIcon } from "../../assets/icons";
import { NumericFormat } from "react-number-format";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import axios from "axios";
import { BaseUrl } from "../../ApiConfig";
import fileDownload from "js-file-download";

function ContactPaymentPlanItem({ data, setData }) {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.userData);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedInstallments, setEditedInstallments] = useState([]);
  const [formData, setFormData] = useState({
    date: "",
    salesPrice: 0,
    cashDiscountRate: 0,
    cashDiscountAmount: 0,
    installments: [],
    isCash: false,
  });
  const [sortedInstallments, setSortedInstallments] = useState([]);

  // Add this function to handle form data initialization
  const initializeFormData = (item) => {
    setFormData({
      date: item.date.split("T")[0],
      salesPrice: item.salesPrice,
      cashDiscountRate: item.cashDiscountRate || 0,
      cashDiscountAmount: item.cashDiscountAmount || 0,
      installments: item.installments || [],
      isCash: item.isCash || false,
    });
  };

  // Taksitleri tarihe göre sıralayan fonksiyon
  const sortInstallmentsByDate = (installments) => {
    return [...installments].sort(
      (a, b) => new Date(a.dueDate) - new Date(b.dueDate)
    );
  };

  // Modal açıldığında form verilerini başlatma
  const handleOpenModal = (item) => {
    setSelectedItem(item);
    // Taksitleri sırala ve state'e kaydet
    const sorted = sortInstallmentsByDate(item.installments);
    setSortedInstallments(sorted);
    initializeFormData(item);
    setIsModalVisible(true);
  };

  const handleDownload = async (item) => {
    try {
      const response = await axios({
        url: `${BaseUrl}/api/paymentPlan/export/${item._id}`,
        method: "GET",
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      // Dosya adını oluştur - item bilgilerini kullanarak daha anlamlı bir isim verebiliriz
      const fileName = `Ödeme_Planı_${item?.paymentPlanSettingId?.name}_${
        item?.inventoryId?.inventoryName
      }_${new Date().toLocaleDateString("tr-TR")}.xlsx`;

      // fileDownload kullanarak dosyayı indir
      fileDownload(response.data, fileName);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const currencyConvert = (value) => {
    if (value === "TRY") {
      return "₺";
    } else if (value === "USD") {
      return "$";
    } else if (value === "EUR") {
      return "€";
    } else if (value === "GBP") {
      return "£";
    } else if (value === "RUB") {
      return "₽";
    } else {
      return "₺";
    }
  };

  const updatePaymentPlan = async () => {
    if (selectedItem) {
      try {
        const updatedPaymentPlan = {
          date: formData.date,
          salesPrice: formData.salesPrice,
          cashDiscountRate: formData.cashDiscountRate,
          cashDiscountAmount: formData.cashDiscountAmount,
          installments:
            editedInstallments.length > 0
              ? editedInstallments
              : selectedItem.installments,
        };

        const response = await axios.put(
          `${BaseUrl}/api/paymentPlan/update/${selectedItem._id}`,
          updatedPaymentPlan,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );

        if (response.data.status === "success") {
          // Update the local state
          const updatedData = data.map((item) => {
            if (item._id === selectedItem._id) {
              return {
                ...item,
                ...updatedPaymentPlan,
              };
            }
            return item;
          });

          setData(updatedData);
          setIsEditing(false);
          setIsModalVisible(false);
          setSelectedItem(null);
          setEditedInstallments([]);
          setFormData({
            date: "",
            salesPrice: 0,
            cashDiscountRate: 0,
            cashDiscountAmount: 0,
            installments: [],
          });
        }
      } catch (error) {
        console.error("Error updating payment plan:", error);
      }
    }
  };

  // Toplam tutarı hesaplayan fonksiyon
  const calculateTotalAmount = (installments) => {
    const total = installments.reduce(
      (total, inst) => total + Number(inst.amount),
      0
    );

    return total;
  };

  return data
    ? data.map((item) => {
        let date = new Date(item.date);
        let fullDate =
          date.getDate() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getFullYear();
        return (
          <React.Fragment key={item._id}>
            <div
              key={`data-${item._id}`}
              style={{ zIndex: "0" }}
              className={style.contactBidİtem}
            >
              <div style={{ display: "flex", width: "95%" }}>
                <input
                  key={`checkbox-${item._id}`}
                  type="checkbox"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    let value = e.target.checked;
                    setData(
                      data.map((sd) => {
                        if (sd._id === item._id) {
                          sd.select = value;
                        }
                        return sd;
                      })
                    );
                  }}
                  checked={item.select || false}
                  style={{ marginLeft: 10 }}
                />
                <div className={style.contactPaymentItem}>{fullDate}</div>
                <div className={style.contactPaymentItem}>
                  {item?.paymentPlanSettingId.name}
                </div>
                <div className={style.contactPaymentItem}>
                  <NumericFormat
                    style={{
                      color: "#333e48",
                      width: "100%",
                      textAlign: "center",
                    }}
                    value={item.salesPrice}
                    allowLeadingZeros={false}
                    thousandsGroupStyle="thousand"
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    suffix={currencyConvert(item?.currency)}
                    displayType="text"
                  />
                </div>
                <div className={style.contactPaymentItem}>
                  {item?.inventoryId?.inventoryName}
                </div>
                <div className={style.contactPaymentItem}>
                  {item?.projectId?.projectName}
                </div>
                <div
                  className={style.contactPaymentItem}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleOpenModal(item)}
                >
                  <EditIcon />
                </div>
                <div
                  className={style.contactPaymentItem}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDownload(item)}
                >
                  <DownloadFileIcon />
                </div>
              </div>
            </div>

            <Modal
              title={t("Payment Plan Detail")}
              open={isModalVisible}
              onCancel={() => {
                setIsModalVisible(false);
                setSelectedItem(null);
                setIsEditing(false);
                setFormData({
                  date: "",
                  salesPrice: 0,
                  cashDiscountRate: 0,
                  cashDiscountAmount: 0,
                  installments: [],
                });
              }}
              width={800}
              footer={[
                <button
                  key={`edit-${item._id}`}
                  className={style.editButton}
                  onClick={() => setIsEditing(!isEditing)}
                >
                  {isEditing ? t("Cancel") : t("Edit")}
                </button>,
                isEditing && (
                  <button
                    key={`save-${item._id}`}
                    className={style.saveButton}
                    onClick={() => updatePaymentPlan()}
                  >
                    {t("Save")}
                  </button>
                ),
              ]}
            >
              {selectedItem && (
                <div className={style.modalContent}>
                  <div className={style.mainInfo}>
                    <h3>{selectedItem?.paymentPlanSettingId.name}</h3>
                    <div className={style.detailRow}>
                      <span>{t("Date")}:</span>
                      {isEditing ? (
                        <input
                          key={`date-${item._id}`}
                          type="date"
                          value={formData.date}
                          onChange={(e) =>
                            setFormData({ ...formData, date: e.target.value })
                          }
                        />
                      ) : (
                        <span>{fullDate}</span>
                      )}
                    </div>
                    <div className={style.detailRow}>
                      <span>{t("salesPrice")}:</span>
                      <NumericFormat
                        value={selectedItem.salesPrice}
                        allowLeadingZeros={false}
                        thousandsGroupStyle="thousand"
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        suffix={currencyConvert(selectedItem?.currency)}
                        displayType="text"
                      />
                    </div>

                    {selectedItem?.isCash === true ? (
                      <>
                        <div className={style.detailRow}>
                          <span>{t("cashDiscountRate")}:</span>
                          <span>{selectedItem?.cashDiscountRate}%</span>
                        </div>
                        <div className={style.detailRow}>
                          <span>{t("cashDiscountAmount")}:</span>
                          <NumericFormat
                            value={selectedItem.cashDiscountAmount}
                            allowLeadingZeros={false}
                            thousandsGroupStyle="thousand"
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            suffix={currencyConvert(selectedItem?.currency)}
                            displayType="text"
                          />
                        </div>
                      </>
                    ) : null}
                    <div className={style.detailRow}>
                      <span>{t("uniteIsmi")}:</span>
                      <span>{selectedItem?.inventoryId?.inventoryName}</span>
                    </div>
                    <div className={style.detailRow}>
                      <span>{t("projeAdi")}:</span>
                      <span>{selectedItem?.projectId?.projectName}</span>
                    </div>
                  </div>

                  <div className={style.installmentsSection}>
                    <h4>{t("installments")}</h4>
                    <div className={style.installmentsHeader}>
                      <span>{t("dueDate")}</span>
                      <span>{t("amount")}</span>
                      <span>{t("paymentType")}</span>
                      <span>{t("status")}</span>
                    </div>
                    <div className={style.installmentsContainer}>
                      {sortedInstallments.map((installment, index) => (
                        <div
                          key={`installment-${installment._id}`}
                          className={style.installmentRow}
                        >
                          <span>
                            {isEditing ? (
                              <input
                                key={`date-${installment._id}`}
                                type="date"
                                defaultValue={
                                  new Date(installment.dueDate)
                                    .toISOString()
                                    .split("T")[0]
                                }
                                onChange={(e) => {
                                  const newInstallments = [
                                    ...sortedInstallments,
                                  ];
                                  newInstallments[index] = {
                                    ...installment,
                                    dueDate: e.target.value,
                                  };
                                  setEditedInstallments(newInstallments);
                                  setSortedInstallments(newInstallments);
                                }}
                              />
                            ) : (
                              new Date(installment.dueDate).toLocaleDateString()
                            )}
                          </span>
                          <span>
                            {isEditing ? (
                              <input
                                key={`amount-${installment._id}`}
                                type="number"
                                defaultValue={installment.amount}
                                style={{ textAlign: "right" }}
                                onChange={(e) => {
                                  const newInstallments = [
                                    ...sortedInstallments,
                                  ];
                                  newInstallments[index] = {
                                    ...installment,
                                    amount: Number(e.target.value),
                                  };
                                  setEditedInstallments(newInstallments);
                                  setSortedInstallments(newInstallments);

                                  // Toplam tutarı güncelle
                                  const totalAmount =
                                    calculateTotalAmount(newInstallments);
                                  setSelectedItem({
                                    ...selectedItem,
                                    salesPrice: totalAmount,
                                    installments: newInstallments,
                                  });
                                  setFormData({
                                    ...formData,
                                    salesPrice: totalAmount,
                                  });
                                }}
                              />
                            ) : (
                              <NumericFormat
                                value={installment.amount}
                                allowLeadingZeros={false}
                                thousandsGroupStyle="thousand"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                suffix={currencyConvert(selectedItem?.currency)}
                                displayType="text"
                              />
                            )}
                          </span>
                          <span>
                            {isEditing ? (
                              <select
                                key={`paymentType-${installment._id}`}
                                value={installment.paymentType}
                                style={{ cursor: "pointer" }}
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) => {
                                  const newInstallments = [
                                    ...sortedInstallments,
                                  ];
                                  newInstallments[index] = {
                                    ...installment,
                                    paymentType: e.target.value,
                                  };
                                  setEditedInstallments(newInstallments);
                                  setSortedInstallments(newInstallments);
                                }}
                              >
                                <option value="upfront">{t("upfront")}</option>
                                <option value="regular">{t("regular")}</option>
                                <option value="percentage">
                                  {t("percentage")}
                                </option>
                                <option value="fixed">{t("fixed")}</option>
                                <option value="cash">{t("cash")}</option>
                              </select>
                            ) : (
                              t(installment.paymentType)
                            )}
                          </span>
                          <span>
                            {isEditing ? (
                              <input
                                key={`isPaid-${installment._id}`}
                                type="checkbox"
                                checked={installment.isPaid}
                                style={{ cursor: "pointer" }}
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) => {
                                  const newInstallments = [
                                    ...sortedInstallments,
                                  ];
                                  newInstallments[index] = {
                                    ...installment,
                                    isPaid: e.target.checked,
                                  };
                                  setEditedInstallments(newInstallments);
                                  setSortedInstallments(newInstallments);
                                }}
                              />
                            ) : installment.isPaid ? (
                              t("paid")
                            ) : (
                              t("unpaid")
                            )}
                          </span>
                        </div>
                      ))}
                    </div>

                    <div className={style.totalRow}>
                      <span>{t("total")}</span>
                      <span>
                        <NumericFormat
                          value={calculateTotalAmount(sortedInstallments)}
                          allowLeadingZeros={false}
                          thousandsGroupStyle="thousand"
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          suffix={currencyConvert(selectedItem?.currency)}
                          displayType="text"
                        />
                      </span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              )}
            </Modal>
          </React.Fragment>
        );
      })
    : null;
}

export default ContactPaymentPlanItem;
