/* eslint-disable react/jsx-no-undef */
import styles from "./createoffer.module.scss";
import style from "./createbit.module.scss";
import { SubHeader } from "../../components";
// import CreateBid from "../CreateBid/CreateBid";
import { getAContact } from "../../lib/contactsApi";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import axios from "axios";
import {
  TextInput,
  DropInput,
  DropInput2,
  DateInput,
  DocumentInput2,
  DateAndInput,
  DateAndInpuDownPayment,
  NumberInput,
  DisabledNumberInput,
  DropInputJSON,
  DisabledNumberInput2,
  DropInputJSONProject,
  DropInputJSONProject2,
  DropInputJSON2,
  Textarea,
  DateSelect,
  // WithButtonInput,
  NumberAndDropDown,
  UserInput,
} from "../../components/Forms/Inputs";
import Popup from "../CreateLead/LeadPopup";
import { BaseUrl } from "../../ApiConfig";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

const CreateOffer = () => {
  const { t, i18n } = useTranslation();
  const user = useSelector((state) => state.user.userData);
  // console.log(user);
  const { meetingShapeTR, meetingShapeEN } = useSelector(
    (state) => state.system.meetingShape
  );
  const { bankCreditTR, bankCreditEN } = useSelector(
    (state) => state.system.bankCredit
  );
  const { bidStageTR, bidStageEN } = useSelector(
    (state) => state.system.bidStage
  );
  const { failedStageNoteTR, failedStageNoteEN } = useSelector(
    (state) => state.system.failedStageNote
  );
  const navigate = useNavigate();
  const [selectBidType, setSelectBidType] = useState("");
  const [inventoryId, setInventoryId] = useState();
  const location = useLocation();

  const [reminderType, setReminderType] = useState("yok");
  const [startDate, setStartDate] = useState(
    new Date().setDate(new Date().getDate() + 1)
  );

  const [bid, setBid] = useState({
    bidName: new Date().toLocaleString() + "",
    bidShape: "",
    rentalPeriod: "",
    failedStageNote: "",
    //inventory
    bidAmount: null,
    bidAmountWithVat: 0,
    discountRate: 0,
    currency: "",
    projeName: "",
    projectId: "",
    inventoryName: "",
    ownerInventory: "",
    inventoryId: "",
    bidNote: "",
    meetingShape: "",
    //
    //rentprice

    //----
    bidStage: "Sunum",
    estimateDate: "",
    contractStartDate: "",
    unitRentalPrice: "",
    rentIncrease: "",
    securityDeposit: "",
    carPark: "",
    operatingExpenses: "",
    paymentPlanSetting: "",
    closingDate: "",
    paymentType: "",
    decoration: "",
    advancePayment: "", //kapora
    advancePaymentDate: "",
    downPaymnet: "", //peşinat
    downPaymnetDate: "",
    termDownPaymnet: "",
    termDownPaymnetDate: "",
    bankCredit: "",
    bankCreditDate: "",
    bankToBeUsed: "", //kullanılacak banka
    interestAmount: "", //faiz miktarı yıllık faiz oranı
    deliveryTime: "", //teslim tarihi
    userType: user.userType,
    contactId: window.location.pathname.split("/")[2],
    contact: "",
    ownerBid: user.name + " " + user.surname,
  });

  const [popupController, setPopupController] = useState(false);
  const bidType = selectBidType;
  const [userType, setUserType] = useState("");
  const [vatListChecker, setvatListChecker] = useState(false);

  const [projects, setProjects] = useState();
  const [inventory, setInventory] = useState();
  const [paymentPlanSettings, setPaymentPlanSettings] = useState();

  const [projects2, setProjects2] = useState();
  const [inventory2, setInventory2] = useState();

  const [selectedVatRate, setSelectedVatRate] = useState();
  const [selectedListPrice, setSelectedListPrice] = useState();
  const [selectedListRentPrice, setSelectedListRentPrice] = useState();
  const [selectedInventoryOwner, setSelectedInventoryOwner] = useState();
  const [selectedInventoryCurrency, setSelectedInventoryCurrency] = useState();
  const [fakeBidShape, setFakeBidShape] = useState("Satış");
  const [fin, setFin] = useState(0);
  const [selectRentIncreaseBtn, setSelectRentIncreaseBtn] = useState("");
  const [paymentTypeCheck, setPaymentTypeCheck] = useState(false); // ödeme tipi popup
  const [bidTypeChecker, setBidTypeChecker] = useState(false);
  const [activityPriceChecker, setActivityPriceChecker] = useState(false);
  const [projectSelectionChecker, setProjectSelectionChecker] = useState(false);
  const [meetingShapeChecker, setMeetingShapeChecker] = useState(false);
  const [inventoryNameChecker, setInventoryNameChecker] = useState(false);
  const [errorHandlePopUp, setErrorHandlePopUp] = useState(false);
  const [errorHandleText, setErrorHandleText] = useState("");
  // const [productTypeCheck, setproductTypeCheck] = useState(false);

  const selectRentIncreaseBtnFnc = (value) => {
    setBid((prev) => {
      return {
        ...prev,
        rentIncrease: "",
      };
    });
    if (value === "man") {
      setSelectRentIncreaseBtn("man");
    }
    if (value === "read") {
      setSelectRentIncreaseBtn("read");
    }
  };
  const [paymentPlanFile, setPaymentPlanFile] = useState();

  useEffect(() => {
    var date = new Date();
    let fullDate =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    setBid({ ...bid, closingDate: fullDate });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bid.bidStage]);
  useEffect(() => {
    const kdv = () => {
      let rate1 = selectedVatRate ? selectedVatRate : 0;
      let rate = selectedListPrice - bid.bidAmount;
      let total = (rate / selectedListPrice) * 100;
      let kdvNumber = Number(bid.bidAmount) * (rate1 / 100);
      let total1 = Number(bid.bidAmount) + kdvNumber;
      return setBid({
        ...bid,
        bidAmountWithVat: parseFloat(total1),
        discountRate: total ? total : 0,
      });
    };
    kdv();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bid.bidAmount]);
  useEffect(() => {
    if (selectBidType === "Kiralama") {
      const kdv = () => {
        let rate = selectedListRentPrice - bid.bidAmount;
        let total = (rate / selectedListRentPrice) * 100;
        return setBid({
          ...bid,
          discountRate: total ? total : 0,
        });
      };
      kdv();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bid.bidAmount]);

  useEffect(() => {
    setBid((prev) => {
      return { ...prev, inventoryId: inventoryId, bidShape: selectBidType };
    });
  }, [inventoryId, selectBidType]);

  const id = location.pathname.split("/")[2];
  const _onClick = () => {
    let controlBid = {
      advancePayment: Number(bid.advancePayment ? bid.advancePayment : 0),
      downPaymnet: Number(bid.downPaymnet ? bid.downPaymnet : 0),
      termDownPaymnet: Number(bid.termDownPaymnet ? bid.termDownPaymnet : 0),
      bankCredit: Number(bid.bankCredit ? bid.bankCredit : 0),
    };

    if (
      selectBidType === "" ||
      selectBidType === undefined ||
      selectBidType == null
    ) {
      return setBidTypeChecker(true);
    }
    if (bid.meetingShape === "") {
      return setMeetingShapeChecker(true);
    }
    if (
      bid.projeName === "" ||
      bid.projeName === undefined ||
      bid.projeName == null
    ) {
      return setProjectSelectionChecker(true);
    }
    if (bid.inventoryName === "") {
      return setInventoryNameChecker(true);
    }
    if (selectBidType === "Satış" && bid.bidAmount <= 0) {
      if (bid.inventoryName.localeCompare("Genel") !== 0) {
        return setvatListChecker(true);
      }
    }
    if (selectBidType === "Kiralama" && bid.bidAmount <= 0) {
      return setActivityPriceChecker(true);
    }

    // setErrorHandleText("");
    // if(bid.bidStage === "" || bid.bidStage === undefined || bid.bidStage === null){
    //   setErrorHandleText("Lütfen Aktivite Aşamasını Seçiniz");
    //   return setErrorHandlePopUp(true);
    // }

    // deafult sunum diye secenek var ondan

    if (
      selectBidType === "Satış" &&
      (bid.bidStage === "Satış(Kaparo)" ||
        bid.bidStage === "Satış(Sözleşme)") &&
      (bid.paymentType === "" ||
        bid.paymentType === undefined ||
        bid.paymentType == null)
    ) {
      return setPaymentTypeCheck(true);
    }

    if (
      Number(bid.bidAmount ? bid.bidAmount : 0) !==
        controlBid.advancePayment +
          controlBid.downPaymnet +
          controlBid.bankCredit +
          controlBid.termDownPaymnet &&
      (userType === "Bireysel" || userType === "Kurumsal") &&
      selectBidType === "Satış" &&
      (bid.bidStage === "Satış(Kaparo)" ||
        bid.bidStage === "Satış(Sözleşme)" ||
        bid.bidStage === "Opsiyon")
    ) {
      return setPopupController(true);
    } else {
      let inventoryItems = {
        bidAmount: bid.bidAmount,
        bidAmountWithVat: bid.bidAmountWithVat,
        projeName: bid.projeName,
        inventoryName: bid.inventoryName,
        discountRate: bid.discountRate,
        ownerInventory: selectedInventoryOwner,
        inventoryId: inventoryId,
      };
      let reminderTime = {
        startDate: startDate,
        reminderType: reminderType,
        language: i18n.language,
      };

      if (bid.bidNote.trim().length < 5) {
        setErrorHandleText("");
        setErrorHandleText(t("notCharacterSizeLimit"));
        setErrorHandlePopUp(true);
        return;
      }

      bid.contact.bids = [];
      bid.contact.notes = [];

      console.log("Bid object before submit:", bid); // Debug için eklendi
      let formData = new FormData();
      formData.append("documentPaymentPlan", paymentPlanFile);
      formData.append("bid", JSON.stringify(bid));
      formData.append("inventoryItems", JSON.stringify(inventoryItems));
      formData.append("reminderDate", JSON.stringify(reminderTime));

      axios
        .post(`${BaseUrl}/api/bid/createBid/${id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${user.token}`,
          },
        })
        .then((res) => {
          const note = {
            title: res.data.bid.bidName,
            note: bid.bidNote,
            bids: res.data.bid._id,
            connectedProject: res.data.bid.projectId,
            meetingDate: res.data.bid.createAtReal,
            noteOwner: user.name + " " + user.surname,
            contactId: res.data.contact._id,
            userType: user.userType,
            queryBidName: res.data.bid.bidName,
            queryContactName: res.data.bid.queryContactName,
            queryInventoryName: res.data.bid.inventoryName,
            queryProjectName: res.data.bid.projeName,
          };
          if (bid.bidNote.length > 0) {
            axios
              .post(`${BaseUrl}/api/note/add/${id}`, note, {
                headers: {
                  Authorization: `Bearer ${user.token}`,
                },
              })
              .then((res) => {
                //console.log(res);
              })
              .catch((err) => {
                console.log(err);
              });
          }
          navigate(`/contact-detail/${id}`, { replace: true });
          window.location.reload();
          //console.log(res.data);
        })
        .catch((error) => {
          setErrorHandleText("");
          console.log(error);
          setErrorHandleText(error?.response?.data?.message);
          setErrorHandlePopUp(true);
          //console.log(error.response)
        });
    }
  };

  useEffect(() => {
    setUserType(user.userType);
    const contactid = location.pathname.split("/")[2];
    getAContact(user.token, contactid)
      .then((res) => {
        setBid((prev) => {
          return { ...prev, contact: res.data.contact };
        });
      })
      .catch((error) => {
        navigate("/contact-list", { replace: true });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    axios
      .get(`${BaseUrl}/api/project/getAllOffer`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        setProjects(res.data.project);
        setProjects2(res.data.project);
      })
      .catch((error) => console.log(error.response));
  }, [userType, fin, user.token]);

  useEffect(() => {
    if (bid.projectId) {
      axios
        .get(`${BaseUrl}/api/paymetPlanSetting/get/${bid.projectId}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
        .then((res) => {
          setPaymentPlanSettings(res.data.paymentPlanSetting);
        })
        .catch((error) => {
          console.error(error.response);
        });
    }
  }, [bid.projectId, user.token]);

  const currencyConvert = (value) => {
    if (value === "TRY") {
      return " ₺";
    } else if (value === "USD") {
      return " $";
    } else if (value === "EUR") {
      return " €";
    } else if (value === "GBP") {
      return " £";
    } else if (value === "RUB") {
      return " ₽";
    } else {
      return " ₺";
    }
  };
  const currentcyNameConverter = (value) => {
    if (value === "TRY") {
      return t("try");
    } else if (value === "USD") {
      return t("usd");
    } else if (value === "EUR") {
      return t("eur");
    } else if (value === "GBP") {
      return t("gbp");
    } else if (value === "RUB") {
      return t("rub");
    } else {
      return t("try");
    }
  };

  return (
    <>
      <div className={styles.createOffer}>
        <SubHeader
          setBid={setBid}
          onClick={_onClick}
          createBid
          path={`/contact-detail/${id}`}
          // buttonName="Kaydet ve Yeni Ekle"
          newButtonName={t("kaydet")}
          form
          createBidHistoryId={id}
        />
        <div className={styles.content}>
          <div className={style.createBidContent}>
            <div className={style.bidForm}>
              <div className={style.leftBidForm}>
                <TextInput
                  disabled
                  title={t("musteriAdi")}
                  value={
                    bid.contact
                      ? `${bid.contact.name} ${bid.contact.surname}`
                      : ""
                  }
                  placeholder={t("musteriAdi")}
                />
                <DropInput
                  data={[
                    { name: t("satisDepertmani"), value: "Satış" },
                    { name: t("kiralamaDepartmani"), value: "Kiralama" },
                  ]}
                  title={t("aktiviteTipi")}
                  placeholder={t("aktiviteTipiSeciniz")}
                  setDropValue={(e) => {
                    setSelectBidType(e);
                    setFakeBidShape(e);
                    setBid({ ...bid, bidAmount: null });
                  }}
                  styleType={2}
                />
                <TextInput
                  disabled
                  title={t("aktiviteIsmi")}
                  placeholder={new Date().toLocaleString() + ""}
                  onChange={(text) =>
                    setBid((prev) => {
                      return { ...prev, bidName: text };
                    })
                  }
                />
                <UserInput
                  value={user.name + " " + user.surname}
                  onChange={(text) => {}}
                  title={t("olusturanPersonel")}
                  placeholder={user.name + " " + user.surname}
                />
                {user.name !== undefined && (
                  <DropInput
                    data={
                      i18n.language === "tr"
                        ? meetingShapeTR
                        : i18n.language === "en"
                        ? meetingShapeEN
                        : null
                    }
                    setDropValue={(text) => {
                      setBid({ ...bid, meetingShape: text });
                    }}
                    placeholder={t("gorusmeKanali")}
                    title={t("gorusmeKanali")}
                    styleType={2}
                  />
                )}
                {fakeBidShape === "Satış" ? (
                  <>
                    <DropInputJSONProject
                      title={t("iliskiliProje")}
                      click={() => {
                        setFin(fin + 1);
                      }}
                      placeholder={t("iliskiliProjeSeciniz")}
                      setBid={setBid}
                      data={
                        projects
                          ? projects.map((item, index) => {
                              return {
                                name: item.projectName,
                                value: {
                                  inventory: item.inventory,
                                  projectName: item.projectName,
                                },
                              };
                            })
                          : null
                      }
                      setDropValue={(text) => {
                        setSelectedListRentPrice("");
                        setSelectedVatRate("");
                        setSelectedInventoryOwner("");
                        setSelectedListPrice("");
                        bid.inventoryName = "";
                        setInventoryId("");
                        setInventory("");
                        let rentInventory = JSON.parse(text).inventory.filter(
                          (item) => {
                            if (selectBidType === "Kiralama") {
                              return (
                                item.inventoryCategory === "kiralık" ||
                                item.inventoryCategory === "satılıkveyakiralık"
                              );
                            } else if (selectBidType === "Satış") {
                              return (
                                item.inventoryCategory === "satılık" ||
                                item.inventoryCategory === "satılıkveyakiralık"
                              );
                            }
                            return [];
                          }
                        );

                        let findProject = projects.find((item) => {
                          return (
                            item.projectName === JSON.parse(text).projectName
                          );
                        });
                        bid.projectId = findProject._id;
                        setInventory(rentInventory);
                        setBid({
                          ...bid,
                          projeName: JSON.parse(text).projectName,
                        });
                      }}
                    />
                    <DropInputJSON
                      not
                      data={
                        inventory
                          ? inventory
                              .map((item) => {
                                return {
                                  value: {
                                    vatRate: item?.vatRate,
                                    inventoryOwner:
                                      item?.inventoryOwnerId?.name +
                                      " " +
                                      item?.inventoryOwnerId?.surname,
                                    excludingVatListPrice:
                                      item?.excludingVatListPrice,
                                    inventoryName: item?.inventoryName,
                                    id: item?._id,
                                    rentPrice: item?.rentPrice,
                                    currency:
                                      (item?.currency === "") | null | undefined
                                        ? "TRY"
                                        : item?.currency,
                                  },
                                  name: item?.inventoryName,
                                  status: item?.status,
                                };
                              })
                              .filter((item) => {
                                return item.status !== "Satışa Kapalı";
                              })
                          : null
                      } // for test
                      title={t("urunTipi")}
                      dropValue={bid.inventoryName === "" ? "asda" : null}
                      setDropValue={(text) => {
                        const inventoryCurrency = JSON.parse(text).currency;
                        setSelectedInventoryCurrency(inventoryCurrency);
                        setSelectedListRentPrice(JSON.parse(text).rentPrice);
                        setSelectedVatRate(JSON.parse(text).vatRate);
                        setSelectedInventoryOwner(
                          JSON.parse(text).inventoryOwner
                        );
                        setSelectedListPrice(
                          JSON.parse(text).excludingVatListPrice
                        );
                        setBid({
                          ...bid,
                          inventoryName: JSON.parse(text).inventoryName,
                          currency: inventoryCurrency,
                        });
                        // console.log(bid.inventoryName)
                        setInventoryId(JSON.parse(text).id);
                      }}
                      placeholder={t("birUrunTipiSeciniz")}
                    />
                  </>
                ) : fakeBidShape === "Kiralama" ? (
                  <>
                    <DropInputJSONProject2
                      title={t("iliskiliProje")}
                      click={() => {
                        setFin(fin + 1);
                      }}
                      placeholder={t("iliskiliProjeSeciniz")}
                      setBid={setBid}
                      data={
                        projects2
                          ? projects2.map((item, index) => {
                              return {
                                name: item.projectName,
                                value: {
                                  inventory: item.inventory,
                                  projectName: item.projectName,
                                },
                              };
                            })
                          : null
                      }
                      setDropValue={(text) => {
                        let rentInventory = JSON.parse(text).inventory.filter(
                          (item) => {
                            if (selectBidType === "Kiralama") {
                              return (
                                item.inventoryCategory === "kiralık" ||
                                item.inventoryCategory === "satılıkveyakiralık"
                              );
                            } else if (selectBidType === "Satış") {
                              return (
                                item.inventoryCategory === "satılık" ||
                                item.inventoryCategory === "satılıkveyakiralık"
                              );
                            }
                            return [];
                          }
                        );

                        let findProject = projects2.find((item) => {
                          return (
                            item.projectName === JSON.parse(text).projectName
                          );
                        });

                        bid.projectId = findProject._id;

                        setInventory2(rentInventory);
                        setBid({
                          ...bid,
                          projeName: JSON.parse(text).projectName,
                        });
                      }}
                    />
                    <DropInputJSON2
                      not
                      data={
                        inventory2
                          ? inventory2.map((item) => {
                              return {
                                value: {
                                  vatRate: item.vatRate,
                                  inventoryOwner:
                                    item.inventoryOwnerId.name +
                                    " " +
                                    item.inventoryOwnerId.surname,
                                  excludingVatListPrice:
                                    item.excludingVatListPrice,
                                  inventoryName: item.inventoryName,
                                  id: item._id,
                                  rentPrice: item.rentPrice,
                                },
                                name: item.inventoryName,
                              };
                            })
                          : null
                      }
                      title={t("urunTipi")}
                      setDropValue={(text) => {
                        alert(JSON.parse(text).inventoryName);
                        setSelectedListRentPrice(JSON.parse(text).rentPrice);
                        setSelectedVatRate(JSON.parse(text).vatRate);
                        setSelectedInventoryOwner(
                          JSON.parse(text).inventoryOwner
                        );
                        setSelectedListPrice(
                          JSON.parse(text).excludingVatListPrice
                        );
                        setBid({
                          ...bid,
                          inventoryName: JSON.parse(text).inventoryName,
                        });
                        setInventoryId(JSON.parse(text).id);
                      }}
                      placeholder={t("birUrunTipiSeciniz")}
                    />
                  </>
                ) : null}

                {selectedInventoryOwner ? (
                  <TextInput
                    disabled
                    title={t("malSahibi")}
                    value={selectedInventoryOwner}
                  />
                ) : null}

                {selectedListPrice ? (
                  <>
                    <TextInput
                      disabled
                      title={t("uniteDovizBirimi")}
                      placeholder={currentcyNameConverter(
                        selectedInventoryCurrency
                      )}
                    />
                    <DisabledNumberInput
                      suffix={currencyConvert(selectedInventoryCurrency)}
                      title={t("kdvHaricListeFiyat")}
                      value={selectedListPrice}
                    />
                  </>
                ) : null}

                {bidType === "Satış" && bid.inventoryName !== "" ? (
                  <>
                    <DropInput2
                      isDefault={bid.currency === "" ? "TRY" : bid.currency}
                      data={[
                        { name: t("try"), value: "TRY" },
                        { name: t("usd"), value: "USD" },
                        { name: t("eur"), value: "EUR" },
                        { name: t("gbp"), value: "GBP" },
                        { name: t("rub"), value: "RUB" },
                      ]}
                      title={t("aktiviteDovizBirimi")}
                      placeholder={t("aktiviteDovizBirimi")}
                      setDropValue={(text) => {
                        setBid((prev) => {
                          return { ...prev, currency: text };
                        });
                      }}
                    />
                    <NumberInput
                      suffix={currencyConvert(bid.currency)}
                      title={t("kdvHaricSatisFiyat")}
                      placeholder={t("kdvHaricSatisFiyat")}
                      value={bid.bidAmount}
                      onChange={(text) => {
                        setBid((prev) => {
                          return {
                            ...prev,
                            bidAmount: text,
                          };
                        });
                      }}
                    />
                    <DisabledNumberInput
                      title={t("kdv")}
                      suffix={currencyConvert(bid.currency)}
                      placeholder={t("kdvVadelendirilicakTutar")}
                      value={
                        bid.bidAmountWithVat - bid.bidAmount
                        // (Number(bid.termDownPaymnet) * 8) / 100
                        // + Number(bid.termDownPaymnet)
                      }
                      disabled
                    />
                    <TextInput
                      disabled
                      title={t("kdvOrani")}
                      placeholder={selectedVatRate && "%" + selectedVatRate}
                    />

                    <DisabledNumberInput
                      suffix={currencyConvert(bid.currency)}
                      title={t("kdvDahilSatisTutari")}
                      placeholder={t("kdvDahilSatisTutari")}
                      value={bid.bidAmountWithVat}
                      disabled
                    />
                    <DisabledNumberInput2
                      disabled
                      title={t("indirimOrani")}
                      value={bid.discountRate ? bid.discountRate.toFixed(0) : 0}
                    />
                    <DateAndInpuDownPayment
                      suffix={currencyConvert(bid.currency)}
                      textValue={Number(bid.advancePayment).toFixed(2)}
                      textOnChange={(text) =>
                        setBid((prev) => {
                          return { ...prev, advancePayment: text };
                        })
                      }
                      dateOnchange={(text) =>
                        setBid((prev) => {
                          return { ...prev, advancePaymentDate: text };
                        })
                      }
                      title={t("kapora")}
                      textPlaceHolder={t("kapora")}
                    />
                    <DateAndInput
                      suffix={currencyConvert(bid.currency)}
                      textValue={Number(bid.downPaymnet).toFixed(2)}
                      textOnChange={(text) =>
                        setBid((prev) => {
                          return { ...prev, downPaymnet: text };
                        })
                      }
                      dateOnchange={(text) =>
                        setBid((prev) => {
                          return { ...prev, downPaymnetDate: text };
                        })
                      }
                      title={t("pesinat")}
                      textPlaceHolder={t("pesinat")}
                    />
                    <DateAndInput
                      suffix={currencyConvert(bid.currency)}
                      textValue={Number(bid.termDownPaymnet).toFixed(2)}
                      textOnChange={(text) =>
                        setBid((prev) => {
                          return { ...prev, termDownPaymnet: text };
                        })
                      }
                      dateOnchange={(text) =>
                        setBid((prev) => {
                          return { ...prev, termDownPaymnetDate: text };
                        })
                      }
                      title={t("vadelendirilecekTutar")}
                      textPlaceHolder={t("vadelendirilecekTutar")}
                    />
                    <DateAndInput
                      suffix={currencyConvert(bid.currency)}
                      textValue={Number(bid.bankCredit).toFixed(2)}
                      textOnChange={(text) =>
                        setBid((prev) => {
                          return { ...prev, bankCredit: text };
                        })
                      }
                      dateOnchange={(text) =>
                        setBid((prev) => {
                          return { ...prev, bankCreditDate: text };
                        })
                      }
                      title={t("bankaKredisi")}
                      textPlaceHolder={t("bankaKredisi")}
                    />
                    <DropInputJSON2
                      not
                      data={
                        paymentPlanSettings
                          ? paymentPlanSettings.map((item) => {
                              return {
                                value: item._id,
                                name: item.name,
                              };
                            })
                          : null
                      }
                      title={t("odemePlani")}
                      setDropValue={(text) => {
                        console.log(text);
                        setBid({
                          ...bid,
                          paymentPlanSetting: JSON.parse(text),
                        });
                      }}
                      placeholder={t("odemePlaniSeciniz")}
                    />
                  </>
                ) : bidType === "Kiralama" ? (
                  <div>
                    <DropInput2
                      isDefault={bid?.currency === "" ? "TRY" : bid?.currency}
                      data={[
                        { name: t("try"), value: "TRY" },
                        { name: t("usd"), value: "USD" },
                        { name: t("eur"), value: "EUR" },
                        { name: t("gbp"), value: "GBP" },
                        { name: t("rub"), value: "RUB" },
                      ]}
                      title={t("aktiviteDovizBirimi")}
                      placeholder={t("aktiviteDovizBirimi")}
                      setDropValue={(text) => {
                        setBid((prev) => {
                          return { ...prev, currency: text };
                        });
                      }}
                    />
                    <NumberInput
                      suffix={currencyConvert(bid?.currency)}
                      title={t("aktiviteTutari")}
                      placeholder={t("aktiviteTutari")}
                      value={bid?.bidAmount}
                      onChange={(text) => {
                        setBid((prev) => {
                          return {
                            ...prev,
                            bidAmount: text,
                          };
                        });
                      }}
                    />
                    <DisabledNumberInput2
                      disabled
                      title={t("indirimOrani")}
                      value={bid.discountRate ? bid.discountRate.toFixed(0) : 0}
                    />
                    <TextInput
                      title={t("kiralamaYil")}
                      placeholder={t("kiralamaYil")}
                      onChange={(text) =>
                        setBid((prev) => {
                          return { ...prev, rentalPeriod: text };
                        })
                      }
                    />

                    <DateInput
                      title={t("sozlesmeBaslangicTarihi")}
                      onChange={(text) =>
                        setBid((prev) => {
                          return { ...prev, contractStartDate: text };
                        })
                      }
                    />
                  </div>
                ) : null}
              </div>
              <div className={style.rightBidForm}>
                {bidType === "Satış" && bid.inventoryName !== "" ? (
                  <div>
                    <DropInput
                      title={t("krediKullanilanBanka")}
                      placeholder={t("krediKullanilanBanka")}
                      setDropValue={(text) =>
                        setBid((prev) => {
                          return { ...prev, bankToBeUsed: text };
                        })
                      }
                      data={
                        i18n.language === "tr"
                          ? bankCreditTR
                          : i18n.language === "en"
                          ? bankCreditEN
                          : null
                      }
                      styleType={2}
                    />

                    {/* <DisabledNumberInput
                      disabled
                      title="KDV Dahil Liste Fiyatı"
                      value={inventory.withVatListPrice}
                      placeholder={'KDV Dahil Satış Tutarı'}
                    /> */}
                    <TextInput
                      title={t("faizMiktariYillikOran")}
                      placeholder={t("faizMiktariYillikOran")}
                      onChange={(text) =>
                        setBid((prev) => {
                          return { ...prev, interestAmount: text };
                        })
                      }
                    />
                    <DateInput
                      key={"deliveryTime"}
                      value={bid?.deliveryTime}
                      title={t("teslimTarihi")}
                      onChange={(text) =>
                        setBid((prev) => {
                          return {
                            ...prev,
                            deliveryTime: text,
                          };
                        })
                      }
                    />
                    <DropInput
                      value={bid.paymentType}
                      data={[
                        { value: "Tamamı Peşin", name: t("tamamiPesin") },
                        { value: "Kredili", name: t("kredili") },
                        { value: "Kredili + Vadeli", name: t("krediliVadeli") },
                        { value: "Vadeli", name: t("vadeli") },
                      ]}
                      title={t("odemeTipi")}
                      placeholder={t("odemeTipi")}
                      setDropValue={(text) =>
                        setBid((prev) => {
                          return { ...prev, paymentType: text };
                        })
                      }
                      styleType={2}
                    />
                    <DocumentInput2
                      setDocumentFile={setPaymentPlanFile}
                      documentFile={paymentPlanFile}
                    />
                  </div>
                ) : null}
                {userType === "Kurumsal" && bidType === "Kiralama" ? (
                  <>
                    <NumberInput
                      suffix={currencyConvert(bid.currency)}
                      title={t("birimKiraBedeli")}
                      placeholder={t("birimKiraBedeli")}
                      value={bid.unitRentalPrice}
                      onChange={(text) => {
                        setBid((prev) => {
                          return {
                            ...prev,
                            unitRentalPrice: text,
                          };
                        });
                      }}
                    />

                    {/* <
                    suffix={currencyConvert(bid.currency)}
                    title={'Kira Artışı'}
                    placeholder={'Kira Artışı'}
                    value={bid.rentIncrease}
                    onChange={text => {
                      setBid(prev => {
                        return {
                          ...prev,
                          rentIncrease: text,
                        };
                      });
                    }}
                  /> */}
                    <div
                      style={{
                        width: "310px",
                        marginLeft: "9.4rem",
                        marginBottom: "0.5rem",
                        fontSize: "14px",
                        color: "grey",
                        borderBottom: "2px solid #e2e2e2",
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <button
                        style={{
                          padding: "5px",
                          color: "grey",
                          backgroundColor: "transparent",
                          border:
                            selectRentIncreaseBtn === "man"
                              ? "1px solid  #646464 "
                              : "1px solid #e2e2e2",
                          marginBottom: "5px",
                          cursor: "pointer",
                        }}
                        onClick={() => selectRentIncreaseBtnFnc("man")}
                      >
                        {t("manuel")}
                      </button>
                      <button
                        style={{
                          padding: "5px",
                          color: "grey",
                          backgroundColor: "transparent",
                          border:
                            selectRentIncreaseBtn === "read"
                              ? "1px solid  #646464"
                              : "1px solid  #e2e2e2 ",
                          cursor: "pointer",
                          marginBottom: "5px",
                        }}
                        onClick={() => selectRentIncreaseBtnFnc("read")}
                      >
                        {t("hazir")}
                      </button>
                    </div>
                    <NumberAndDropDown
                      title={t("kiraArtisi")}
                      placeholder={t("kiraArtisi")}
                      value={bid.rentIncrease}
                      selectRentIncreaseBtn={selectRentIncreaseBtn}
                      onChange={(text) => {
                        setBid((prev) => {
                          return {
                            ...prev,
                            rentIncrease: text,
                          };
                        });
                      }}
                    />
                    <DropInput
                      title={t("guvenceBedeliAy")}
                      placeholder={t("guvenceBedeliAy")}
                      data={[
                        { name: "1", value: "1" },
                        { name: "2", value: "2" },
                        { name: "3", value: "3" },
                        { name: "4", value: "4" },
                        { name: "5", value: "5" },
                        { name: "6", value: "6" },
                        { name: "7", value: "7" },
                        { name: "8", value: "8" },
                        { name: "9", value: "9" },
                        { name: "10", value: "10" },
                        { name: "11", value: "11" },
                        { name: "12", value: "12" },
                      ]}
                      setDropValue={(text) =>
                        setBid((prev) => {
                          return { ...prev, securityDeposit: text };
                        })
                      }
                      styleType={2}
                    />
                    <TextInput
                      title={t("otopark")}
                      placeholder={t("otopark")}
                      onChange={(text) =>
                        setBid((prev) => {
                          return { ...prev, carPark: text };
                        })
                      }
                    />

                    <DropInput
                      title={t("dekorasyon")}
                      placeholder={t("dekorasyon")}
                      data={[
                        { name: t("var"), value: "Var" },
                        { name: t("yok"), value: "Yok" },
                      ]}
                      setDropValue={(text) =>
                        setBid((prev) => {
                          return { ...prev, decoration: text };
                        })
                      }
                      styleType={2}
                    />

                    <h1
                      style={{
                        width: "340px",
                        marginLeft: "9.4rem",
                        marginBottom: "0.5rem",
                        fontSize: "14px",
                        color: "grey",
                      }}
                    >
                      {t("isletmeGiderleriText")}
                    </h1>
                    <NumberInput
                      suffix={currencyConvert(bid.currency)}
                      title={t("isletmeGideri")}
                      placeholder={t("isletmeGideri")}
                      value={bid.operatingExpenses}
                      onChange={(text) => {
                        setBid((prev) => {
                          return {
                            ...prev,
                            operatingExpenses: text,
                          };
                        });
                      }}
                    />
                  </>
                ) : null}
                {bidType.length ? (
                  <>
                    <div
                      style={bidType === "Satış" ? { marginTop: "19px" } : null}
                    ></div>
                    <DropInput
                      title={t("aktiviteAsamasi")}
                      placeholder={t("aktiviteAsamasiSeciniz")}
                      data={
                        i18n.language === "tr"
                          ? bidStageTR
                          : i18n.language === "en"
                          ? bidStageEN
                          : null
                      }
                      setDropValue={(text) =>
                        setBid((prev) => {
                          return { ...prev, bidStage: text };
                        })
                      }
                      styleType={2}
                    />
                  </>
                ) : null}

                {bid.bidStage === "Başarılı Kapanış" ||
                bid.bidStage === "Başarısız Kapanış" ? (
                  <>
                    <TextInput
                      disabled
                      title={t("kapanisTarihi")}
                      placeholder={bid.closingDate}
                    />
                    {bid.bidStage === "Başarısız Kapanış" ? (
                      <DropInput
                        title={t("basarisizKapanisNedeni")}
                        placeholder={t("basarisizKapanisNedeni")}
                        data={
                          i18n.language === "tr"
                            ? failedStageNoteTR
                            : i18n.language === "en"
                            ? failedStageNoteEN
                            : null
                        }
                        setDropValue={(text) =>
                          setBid((prev) => {
                            return { ...prev, failedStageNote: text };
                          })
                        }
                        styleType={2}
                      />
                    ) : null}
                  </>
                ) : null}
                {bidType === "Kiralama" || bidType === "Satış" ? (
                  <Textarea
                    title={t("not")}
                    //value={bidNote}
                    placeholder={t("aktiviteNotuGiriniz")}
                    onChange={(text) => {
                      setBid((prev) => {
                        return { ...prev, bidNote: text };
                      });
                    }}
                  />
                ) : null}
                {bid.inventoryName !== "" && (
                  <>
                    <DropInput2
                      isDefault={reminderType === "" ? "yok" : reminderType}
                      data={[
                        { name: t("yok"), value: "yok" },
                        { name: t("email"), value: "email" },
                      ]}
                      title={t("hatirlatici")}
                      placeholder={t("hatirlaticiTipiSeciniz")}
                      setDropValue={(text) => {
                        setReminderType(text);
                      }}
                    />
                    {reminderType === "email" && (
                      <DateSelect
                        title={t("epostaninTarihi")}
                        email={user.email}
                        startDate={startDate}
                        setStartDate={setStartDate}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {bidTypeChecker ? (
          <Popup
            title={t("yaniAktivitePopupTitle")}
            errorText={t("yaniAktivitePopupAtiviteTuruSec")}
            closeClick={() => setBidTypeChecker(false)}
          />
        ) : null}

        {errorHandlePopUp ? (
          <Popup
            title={t("yaniAktivitePopupTitle")}
            errorText={errorHandleText}
            closeClick={() => setErrorHandlePopUp(false)}
          />
        ) : null}

        {projectSelectionChecker ? (
          <Popup
            title={t("yaniAktivitePopupTitle")}
            errorText={t("yaniAktivitePopupProjeSec")}
            closeClick={() => setProjectSelectionChecker(false)}
          />
        ) : null}
        {meetingShapeChecker ? (
          <Popup
            title={t("yaniAktivitePopupTitle")}
            errorText={t("yaniAktivitePopupGorusmeKanaliSec")}
            closeClick={() => setMeetingShapeChecker(false)}
          />
        ) : null}
        {inventoryNameChecker ? (
          <Popup
            title={t("yaniAktivitePopupTitle")}
            errorText={t("yaniAktivitePopupUrunTipiSec")}
            closeClick={() => setInventoryNameChecker(false)}
          />
        ) : null}

        {vatListChecker ? (
          <Popup
            title={t("yaniAktivitePopupTitle")}
            errorText={t("yaniAktivitePopupKdvHaricListe")}
            closeClick={() => setvatListChecker(false)}
          />
        ) : null}

        {activityPriceChecker ? (
          <Popup
            title={t("yaniAktivitePopupTitle")}
            errorText={t("yaniAktivitePopupAktiviteTutari")}
            closeClick={() => setActivityPriceChecker(false)}
          />
        ) : null}

        {paymentTypeCheck ? (
          <Popup
            title={t("yaniAktivitePopupTitle")}
            errorText={t("yaniAktivitePopupOdemeTipi")}
            closeClick={() => setPaymentTypeCheck(false)}
          />
        ) : null}

        {popupController ? (
          <Popup
            title={t("yaniAktivitePopupTitle")}
            errorText={t("yaniAktivitePopupKaporaPesinatVade")}
            closeClick={() => setPopupController(false)}
          />
        ) : null}
      </div>
    </>
  );
};

export default CreateOffer;
