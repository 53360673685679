import {
  UserIcon,
  DocumentIcon,
  CaseIcon,
  ContactIcon,
} from "../../assets/icons";
import style from "./forms.module.scss";
import { NumericFormat, PatternFormat } from "react-number-format";
import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import tr from "date-fns/locale/tr";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

registerLocale("tr", tr);

const TextInput = ({
  title,
  value,
  onChange,
  disabled,
  placeholder,
  name,
  maxLength = "35",
  hasTitle = true,
}) => {
  return (
    <label className={style.textInput}>
      {hasTitle ? <h2>{title || ""}</h2> : null}
      <input
        name={name}
        disabled={disabled ? true : null}
        maxLength={maxLength}
        value={value || ""}
        onChange={(event) => onChange(event.target.value)}
        type="text"
        placeholder={placeholder ? placeholder : title}
      />
    </label>
  );
};

const ClickableButtonInput = ({
  title,
  disabled = false,
  placeholder,
  onClick,
}) => {
  return (
    <div className={style.textInput}>
      <h2>{title}</h2>
      <button disabled={disabled} onClick={onClick}>
        {placeholder ? placeholder : title}
      </button>
    </div>
  );
};

const NumberInput = ({
  title,
  value,
  onChange,
  disabled,
  placeholder,
  prefix = undefined,
  suffix = undefined,
  decimalScale = 2,
}) => {
  return (
    <label className={style.textInput}>
      <h2>{title}</h2>
      <NumericFormat
        onValueChange={(event) => onChange(event.floatValue)}
        allowLeadingZeros
        thousandSeparator={"."}
        decimalSeparator={","}
        prefix={prefix}
        suffix={suffix}
        decimalScale={decimalScale}
        value={Number(value).toFixed(2)}
        disabled={disabled ? true : null}
        placeholder={placeholder}
      />
    </label>
  );
};

// const NumberInputExp = ({ title, value, onChange, disabled, placeholder }) => {
//   return (
//     <label className={style.textInput}>
//       <h2>{title}</h2>
//       <NumericFormat
//         // onValueChange={event => onChange(event.floatValue)}
//         allowLeadingZeros
//         thousandSeparator={'.'}
//         decimalSeparator={','}
//         suffix={' ₺'}
//         value={value}
//         disabled={disabled ? true : null}
//         placeholder={placeholder}
//       />
//     </label>
//   );
// };

const GSMInput = ({ title, value, onChange, disabled, placeholder }) => {
  return (
    <label className={style.textInput}>
      <h2>{title}</h2>
      <PatternFormat
        onValueChange={(event) => onChange(event.value)}
        mask="_"
        format="+### (###) ### ## ##"
        value={value}
        disabled={disabled || false}
        placeholder={placeholder}
      />
    </label>
  );
};

const NumberAndDropDown = ({
  title,
  value,
  onChange,
  disabled,
  placeholder,
  selectRentIncreaseBtn,
  suffix = " ₺",
}) => {
  const { t } = useTranslation();

  const handleSelectChange = (event) => {
    if (selectRentIncreaseBtn === "read") {
      onChange(event.target.value);
    }
  };

  return (
    <label className={style.numberAndDrop}>
      <h2>{title}</h2>
      <NumericFormat
        onValueChange={
          selectRentIncreaseBtn === "man"
            ? (event) => onChange(event.floatValue)
            : null
        }
        allowLeadingZeros
        thousandSeparator={"."}
        decimalSeparator={","}
        suffix={suffix}
        style={
          selectRentIncreaseBtn === "man"
            ? { border: "1px solid #646464", width: "140px" }
            : { width: "140px" }
        }
        value={value}
        disabled={selectRentIncreaseBtn !== "man"}
        placeholder={placeholder}
      />
      <select
        disabled={selectRentIncreaseBtn !== "read"}
        onChange={handleSelectChange}
        style={
          selectRentIncreaseBtn === "read"
            ? { border: "1px solid #646464" }
            : {}
        }
        defaultValue=""
      >
        <option key="kiraArtisi" value="" disabled>
          {t("kiraArtisi")}
        </option>
        <option key="tufe" value="Tüfe">
          {t("tufe")}
        </option>
        <option key="ufe" value="Üfe">
          {t("ufe")}
        </option>
        <option key="tufeUfe" value="Tüfe&Üfe">
          {t("tufeUfe")}
        </option>
      </select>
    </label>
  );
};
const NumberInputM2 = ({ title, value, onChange, disabled, placeholder }) => {
  return (
    <label className={style.textInput}>
      <h2>{title}</h2>
      <NumericFormat
        onValueChange={(event) => onChange(event.floatValue)}
        allowLeadingZeros
        // thousandSeparator={"."}
        // decimalSeparator={","}
        suffix={" m²"}
        value={value}
        disabled={disabled ? true : null}
        placeholder={placeholder}
      />
    </label>
  );
};

const DisabledNumberInput = ({ title, value, suffix = " ₺" }) => {
  return (
    <label className={style.textInput}>
      <h2>{title}</h2>
      <NumericFormat
        allowLeadingZeros
        thousandSeparator={"."}
        decimalSeparator={","}
        suffix={suffix}
        value={(value * 1).toFixed(2)}
        disabled
      />
    </label>
  );
};

// const DisabledNumberInput3 = ({ title, value }) => {
//   return (
//     <label className={style.textInput}>
//       <h2>{title}</h2>
//       <NumericFormat
//         allowLeadingZeros
//         thousandSeparator={'.'}
//         decimalSeparator={','}
//         suffix={' ₺'}
//         value={value}
//         disabled
//       />
//     </label>
//   );
// };
const DisabledNumberInput2 = ({ placeholder, title, value }) => {
  return (
    <label className={style.textInput}>
      <h2>{title}</h2>
      <NumericFormat
        placeholder={placeholder}
        allowLeadingZeros
        thousandSeparator={"."}
        decimalSeparator={","}
        prefix={"% "}
        value={value}
        disabled
      />
    </label>
  );
};

const DropInput = ({
  title,
  data,
  setDropValue,
  placeholder,
  styleType = null,
}) => {
  return (
    <label className={styleType !== 2 ? style.dropInput : style.dropInput2}>
      <h2>{title}</h2>
      <select
        className={style.selectOption}
        onChange={(e) => setDropValue(e.target.value)}
        defaultValue="asda"
      >
        <option key="asda" value="asda" disabled>
          {placeholder}
        </option>
        {data
          ? data.map((item, index) => (
              <option key={`${item.value}-${index}`} value={item.value}>
                {item.name}
              </option>
            ))
          : null}
      </select>
    </label>
  );
};
const DropInput2 = ({
  title,
  data,
  setDropValue,
  placeholder,
  click,
  select,
  disabled,
  defaultSelected,
  isDefault = "asda",
}) => {
  return (
    <label className={style.dropInput2} onClick={click}>
      <h2>{title}</h2>
      <select
        disabled={disabled}
        className={style.selectOption}
        onChange={(e) => setDropValue(e.target.value)}
        value={isDefault || undefined}
      >
        <option key="asda" value="asda" disabled>
          {placeholder}
        </option>
        {data
          ? data.map((item, index) => (
              <option key={`${item.value}-${index}`} value={item.value}>
                {item.name} {item.surname ? item.surname : ""}
              </option>
            ))
          : null}
      </select>
    </label>
  );
};

const DateSelect = ({ title, email, startDate, setStartDate }) => {
  const { i18n } = useTranslation();

  const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <button className={style.pickerButton} onClick={onClick} ref={ref}>
      {value}
    </button>
  ));
  return (
    <label className={style.dateContainer}>
      <h2>{title}</h2>

      <div className={style.datePicker}>
        <DatePicker
          locale="tr"
          selected={startDate ? new Date(startDate) : null}
          onChange={(date) => setStartDate(date)}
          closeOnScroll={true}
          customInput={<ExampleCustomInput />}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={10}
          timeCaption="Saat"
          dateFormat="dd/MM/yyyy HH:mm"
          minDate={new Date().setDate(new Date().getDate() + 1)}
        />
        {i18n.language === "tr" ? (
          <div className={style.reminder}>
            <strong>{new Date(startDate).toLocaleString()}</strong> tarihinde{" "}
            <strong>{email}</strong> adresine bu aktivite hakkında hatırlatıcı
            bilgilendirme maili gönderilecektir.
          </div>
        ) : (
          <div className={style.reminder}>
            A reminder mail will be sent to <strong> {email} </strong> on
            <strong> {new Date(startDate).toLocaleString()} </strong>about this
            activity.
          </div>
        )}
      </div>
    </label>
  );
};

const DropInputJSON = ({
  title,
  data,
  setDropValue,
  placeholder,
  not,
  disabled,
  dropValue,
  click,
}) => {
  return (
    <label className={style.dropInput2}>
      <h2>{title}</h2>
      <select
        disabled={disabled}
        onClick={click}
        className={style.selectOption}
        onChange={(e) => {
          setDropValue(e.target.value);
        }}
        value={dropValue || undefined}
      >
        <option key="asda" value="asda" disabled>
          {placeholder}
        </option>

        {data
          ? data.map((item, index) => {
              let a = JSON.stringify(item.value);
              return (
                <option
                  key={`${item.name}-${index}`}
                  value={a}
                  // selected={item.value.inventoryName.toString() === isSelected}
                >
                  {item.name}
                </option>
              );
            })
          : null}
      </select>
    </label>
  );
};
const DropInputJSON2 = ({
  title,
  data,
  setDropValue,
  placeholder,
  not,
  click,
}) => {
  return (
    <label className={style.dropInput2}>
      <h2>{title}</h2>
      <select
        onClick={click}
        className={style.selectOption}
        onChange={(e) => setDropValue(e.target.value)}
        defaultValue="asda"
      >
        <option key="asda" value="asda" disabled>
          {placeholder}
        </option>

        {data
          ? data.map((item, index) => {
              let a = JSON.stringify(item.value);
              return (
                <option key={`${item.name}-${index}`} value={a}>
                  {item.name}
                </option>
              );
            })
          : null}
      </select>
    </label>
  );
};
const DropInputJSONProject = ({
  title,
  data,
  setDropValue,
  placeholder,
  not,
  click,
}) => {
  let withOutProjectNull = data
    ? data.filter((item) => item.name !== "Proje Mevcut Değil")
    : null;
  let withProjectNull = data
    ? data.filter((item) => item.name === "Proje Mevcut Değil")
    : null;

  return (
    <label className={style.dropInput2} onClick={click}>
      <h2>{title}</h2>
      <select
        className={style.selectOption}
        onChange={(e) => setDropValue(e.target.value)}
        defaultValue="asda"
      >
        <option key="asda" value="asda" disabled>
          {placeholder}
        </option>

        {data
          ? withOutProjectNull.map((item, index) => {
              let a = JSON.stringify(item.value);
              return (
                <option key={`${item.name}-${index}-out`} value={a}>
                  {item.name}
                </option>
              );
            })
          : null}
        {data
          ? withProjectNull.map((item, index) => {
              let a = JSON.stringify(item.value);
              return (
                <option key={`${item.name}-${index}`} value={a}>
                  {item.name}
                </option>
              );
            })
          : null}
      </select>
    </label>
  );
};
const DropInputJSONProject2 = ({
  title,
  data,
  setDropValue,
  placeholder,
  not,
  click,
}) => {
  let withOutProjectNull = data
    ? data.filter((item) => item.name !== "Proje Mevcut Değil")
    : null;
  let withProjectNull = data
    ? data.filter((item) => item.name === "Proje Mevcut Değil")
    : null;

  return (
    <label className={style.dropInput2} onClick={click}>
      <h2>{title}</h2>
      <select
        className={style.selectOption}
        onChange={(e) => setDropValue(e.target.value)}
        defaultValue="asda"
      >
        <option key="asda" value="asda" disabled>
          {placeholder}
        </option>

        {data
          ? withOutProjectNull.map((item, index) => {
              let a = JSON.stringify(item.value);
              return (
                <option key={`${item.name}-${index}-out`} value={a}>
                  {item.name}
                </option>
              );
            })
          : null}
        {data
          ? withProjectNull.map((item, index) => {
              let a = JSON.stringify(item.value);
              return (
                <option key={`${item.name}-${index}`} value={a}>
                  {item.name}
                </option>
              );
            })
          : null}
      </select>
    </label>
  );
};
const DropInput3 = ({
  title,
  data,
  setDropValue,
  placeholder,
  fin,
  click,
  setBid,
  disabled,
}) => {
  return (
    <label
      className={style.dropInput2}
      onClick={() => {
        click(fin + 1);
        setBid((prev) => {
          return { ...prev, bidAmount: null, bidAmountWithVat: 0 };
        });
      }}
    >
      <h2>{title}</h2>
      <select
        disabled={disabled}
        className={style.selectOption}
        onChange={(e) => setDropValue(e.target.value)}
        defaultValue="asda"
      >
        <option key="asda" value="asda" disabled>
          {placeholder.length ? placeholder : ""}
        </option>
        {data
          ? data.map((item, index) => {
              let a = JSON.stringify(item.value);
              return (
                <option key={`${item.name}-${index}`} value={a}>
                  {item.name}
                </option>
              );
            })
          : null}
      </select>
    </label>
  );
};

const Textarea = ({ title, onChange, value, placeholder }) => {
  return (
    <label className={style.textarea}>
      <h2>{title}</h2>
      <textarea
        placeholder={placeholder}
        value={value}
        onChange={(event) => onChange(event.target.value)}
      />
    </label>
  );
};

const DisabledInput = ({ title }) => {
  return (
    <label className={style.disabledInput}>
      <h2>{title}</h2>
      <input disabled type="text" placeholder={title} />
    </label>
  );
};

const DocumentInput = ({ setDocumentFile, documentFile, placeholder }) => {
  const { t } = useTranslation();
  return (
    <div className={style.documentDrop}>
      <h2>{t("KVKdokumani")}</h2>
      <label htmlFor="document">
        <h2 style={{ textAlign: "start", marginLeft: "1rem" }}>
          {documentFile
            ? documentFile.name
            : placeholder
            ? placeholder.substr(0, 22)
            : t("dokumanYukleyiniz")}{" "}
        </h2>
        <DocumentIcon />
      </label>
      <input
        type="file"
        id="document"
        onChange={(e) => setDocumentFile(e.target.files[0])}
      />
    </div>
  );
};
const DocumentInput3 = ({
  title2,
  title,
  setDocumentFile,
  documentFile,
  placeholder,
  id,
}) => {
  return (
    <div
      className={style.documentDrop}
      style={{ marginLeft: "-1.8rem", marginBottom: "1.2rem" }}
    >
      <h2>{title}</h2>
      <label htmlFor={id}>
        <h2 style={{ width: "100%", textAlign: "start", paddingLeft: "10px" }}>
          {documentFile
            ? documentFile.name
            : placeholder
            ? placeholder.substr(0, 22)
            : title2}{" "}
        </h2>
        <DocumentIcon />
      </label>
      <input
        type="file"
        id={id}
        onChange={(e) => setDocumentFile(e.target.files[0])}
      />
    </div>
  );
};
const DocumentInputIMG = ({
  title2,
  title,
  setDocumentFile,
  documentFile,
  placeholder,
  id,
}) => {
  return (
    <div
      className={style.documentDrop}
      style={{ marginLeft: "-1.8rem", marginBottom: "1.2rem" }}
    >
      <h2>{title}</h2>
      <label htmlFor={id}>
        <h2 style={{ width: "100%", textAlign: "start", paddingLeft: "10px" }}>
          {documentFile
            ? documentFile.name
            : placeholder
            ? placeholder.substr(0, 22)
            : title2}{" "}
        </h2>
        <DocumentIcon />
      </label>
      <input
        accept="image/png, image/jpeg"
        type="file"
        id={id}
        onChange={(e) => setDocumentFile(e.target.files[0])}
      />
    </div>
  );
};
const DocumentInput2 = ({ setDocumentFile, documentFile, placeholder }) => {
  const { t } = useTranslation();
  return (
    <div className={style.documentDrop} style={{ marginLeft: "-1.8rem" }}>
      <h2>{t("odemePlani")}</h2>
      <label htmlFor="document">
        <h2 style={{ width: "100%", textAlign: "center" }}>
          {documentFile
            ? documentFile.name
            : placeholder
            ? placeholder.substr(0, 22)
            : t("odemePlaniYukleyiniz")}{" "}
        </h2>
        <DocumentIcon />
      </label>
      <input
        type="file"
        id="document"
        onChange={(e) => setDocumentFile(e.target.files[0])}
      />
    </div>
  );
};

const UserInput = ({ title, value, onChange, placeholder }) => {
  return (
    <label className={style.userInput}>
      <h2>{title}</h2>
      <input
        maxLength="30"
        value={value}
        onChange={(event) => onChange(event.target.value)}
        type="text"
        placeholder={placeholder ? placeholder : title}
        disabled
      />
      <button>
        <UserIcon />
      </button>
    </label>
  );
};

const AccountInput = ({ title }) => {
  return (
    <label className={style.accountInput}>
      <h2>{title}</h2>
      <input type="text" placeholder={title} />
      <button>
        <CaseIcon />
      </button>
    </label>
  );
};

const ContactInput = ({ title }) => {
  return (
    <label className={style.contactInput}>
      <h2>{title}</h2>
      <input type="text" placeholder={title} />
      <button>
        <ContactIcon />
      </button>
    </label>
  );
};

const DateInput = ({ value, title, onChange, name, props }) => {
  const formatValue = (val) => {
    if (!val) return "";

    if (typeof val === "string") {
      const date = new Date(val);
      if (!isNaN(date.getTime())) {
        return val;
      }
    }

    if (val instanceof Date) {
      return val.toISOString().split("T")[0];
    }

    return "";
  };

  return (
    <label className={style.dateInput}>
      <h2>{title}</h2>
      <input
        name={name}
        value={formatValue(value)}
        type="date"
        placeholder={title}
        onChange={(event) => onChange(event.target.value)}
        {...props}
      />
      {/* <button>
        <DateIcon />
      </button> */}
    </label>
  );
};

const DateAndInput = ({
  dateValue,
  textValue,
  title,
  textPlaceHolder,
  textOnChange,
  dateOnchange,
  disabled,
  suffix = " ₺",
}) => {
  return (
    <label className={style.dateInput}>
      <h2>{title}</h2>
      <div className={style.dateAndInputContent}>
        <NumericFormat
          onValueChange={(event) => textOnChange(event.floatValue)}
          allowLeadingZeros
          thousandSeparator={"."}
          decimalSeparator={","}
          suffix={suffix}
          value={Number(textValue).toFixed(2)}
          placeholder={textPlaceHolder}
          disabled={disabled ? true : false}
        />

        <input
          disabled={disabled ? true : false}
          value={dateValue}
          type="date"
          onChange={(event) => dateOnchange(event.target.value)}
        />
      </div>
    </label>
  );
};

const DateAndInpuDownPayment = ({
  dateValue,
  textValue,
  title,
  textPlaceHolder,
  textOnChange,
  dateOnchange,
  disabled,
  suffix = " ₺",
}) => {
  return (
    <label className={style.dateInput}>
      <h2>{title}</h2>
      <div className={style.dateAndInputContent}>
        <NumericFormat
          onValueChange={(event) => textOnChange(event.floatValue)}
          allowLeadingZeros
          thousandSeparator={"."}
          decimalSeparator={","}
          suffix={suffix}
          value={Number(textValue).toFixed(2)}
          placeholder={textPlaceHolder}
          disabled={disabled ? true : false}
        />

        <input
          disabled={disabled ? true : false}
          value={new Date().toISOString().substring(0, 10)}
          type="date"
          // disabled
          onChange={(event) => dateOnchange(event.target.value)}
        />
      </div>
    </label>
  );
};

const WithButtonInput = ({
  title,
  value,
  onChange,
  disabled,
  placeholder,
  click,
}) => {
  const { t } = useTranslation();
  return (
    <label className={style.withButtonInput}>
      <h2>{title}</h2>
      <input
        disabled={disabled ? true : null}
        maxLength="30"
        value={value}
        onChange={(event) => onChange(event.target.value)}
        type="text"
        placeholder={placeholder ? placeholder : title}
      />
      <button onClick={click}>{t("ekle")}</button>
    </label>
  );
};

const CheckBoxInput = ({ checked, title, onChange, name }) => {
  return (
    <label className={style.checkboxInput}>
      <h2>{title}</h2>
      <input
        name={name}
        type="checkbox"
        checked={checked || false}
        onChange={(e) => onChange(e.target.checked)}
      />
    </label>
  );
};

/*

const SearchListBox = ({
  title,
  value,
  token,
  baseurl,
  usertype,
}) => {
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  //alert(JSON.stringify(value));
  useEffect(() => {
    const params = { yourParameter: 'yourValue' };
    axios
      .get(`${baseurl}/api/contact/getall/${usertype}?pageNumber=${1}&pageSize=${20}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const itemsWithSelection = response.data.contact.map(item => ({
          name: item.name  + " " + item.surname,
          value: item._id,
          selected: false,
        }));
        setItems(itemsWithSelection);
      })
      .catch((error) => {
        console.error('Error fetching items:', error);
      });
  }, []);

  const handleSearch = (query) => {
    setSearchQuery(query);
    const filtered = items.filter((item) => item.name.toLowerCase().includes(query.toLowerCase()));
    setFilteredItems(filtered);
  };

  const handleItemClick = (index) => {
    const updatedItems = [...items];
    updatedItems[index].selected = !updatedItems[index].selected;
    setItems(updatedItems);

    const selected = updatedItems.filter(item => item.selected).map(item => item.value);
    setSelectedItems(selected);
  };

  return (
    <label className={style.dropInput2}>
      <h2>{title}</h2>
      <input
        type="text"
        placeholder="Search..."
        value={searchQuery?searchQuery:`${value.name} ${value.surname}`}
        onChange={(e) => handleSearch(e.target.value)}
      />
      <ul>
        {filteredItems.length > 0
          ? filteredItems.map((item, index) => (
              <li
                key={index}
                onClick={() => handleItemClick(index)}
                className={item.selected ? 'selected' : ''}
              >
                {item.name}
              </li>
            ))
          : items.map((item, index) => (
              <li
                key={index}
                onClick={() => handleItemClick(index)}
                className={item.selected ? 'selected' : ''}
              >
                {item.name}
              </li>
            ))}
      </ul>
    </label>
  );
};
*/
export {
  // DisabledNumberInput3,
  // NumberInputExp,
  DateAndInpuDownPayment,
  GSMInput,
  TextInput,
  DropInput,
  Textarea,
  DisabledInput,
  DocumentInput,
  DocumentInput2,
  UserInput,
  AccountInput,
  ContactInput,
  DateInput,
  DropInput2,
  DateAndInput,
  DisabledNumberInput,
  DropInput3,
  NumberInput,
  DropInputJSON,
  DisabledNumberInput2,
  NumberInputM2,
  DocumentInput3,
  DropInputJSONProject,
  NumberAndDropDown,
  DropInputJSONProject2,
  DropInputJSON2,
  DocumentInputIMG,
  WithButtonInput,
  DateSelect,
  ClickableButtonInput,
  CheckBoxInput,
  // SearchListBox,
};
