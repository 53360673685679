import React, { useState, useEffect } from "react";
import style from "./elements.module.scss";
import { getPaymentPlan } from "../../lib/paymentPlan";
import ContactPaymentPlanItem from "./ContactPaymentPlanItem";
import { useTranslation } from "react-i18next";

function ContactPlan({ contactId, user }) {
  const { t } = useTranslation();
  const [data, setData] = useState(null);

  useEffect(() => {
    const req = getPaymentPlan(user.token, contactId);
    req
      .then((res) => {
        setData(res.data.data.paymentPlan);
      })
      .catch((error) => {
        setData(null);
      });
  }, [contactId, user.token, user.department, user.userType]);

  return (
    <>
      <section id="bid-card">
        <div className={style.contactBid}>
          <div className={style.contactNav}>
            <div>{t("odemePlani")}</div>
            <div className={style.rigtNav}></div>
          </div>
          <div className={style.contactBidİtemHead}>
            <div className={style.contactPaymentItem}>{t("tarih")}</div>
            <div className={style.contactPaymentItem}>{t("odemePlaniAdi")}</div>
            <div className={style.contactPaymentItem}>{t("salesPrice")}</div>
            <div className={style.contactPaymentItem}>{t("uniteIsmi")}</div>
            <div className={style.contactPaymentItem}> {t("projeAdi")}</div>
            <div className={style.contactPaymentItem}>.</div>
            <div className={style.contactPaymentItem}>.</div>
          </div>
          <ContactPaymentPlanItem setData={setData} data={data} />
        </div>
      </section>
    </>
  );
}

export default ContactPlan;
