import React from "react";
import style from "./elements.module.scss";
import { ContactBidItem } from "../../components";
import { NewButton } from "../../components/SubHeader/Buttons";
// import axios from "axios";
import { useLocation } from "react-router-dom";
import { useState } from "react";
// import { BaseUrl } from "../../ApiConfig";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function ContactBid({ bidsData, setBidsData, contactData }) {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.userData);
  const [checkAll, setCheckAll] = useState(false);
  const location = useLocation();
  const locationId = location.pathname.split("/")[2];
  /*
  const deleteCustomerByIds = () => {
    let arrayids = [];
    bidsData.forEach((d) => {
      if (d.select) {
        arrayids.push(d.id);
      }
    });
    axios
      .post(`${BaseUrl}/api/bid/delete/${arrayids}`, {
        contactId: window.location.pathname.split("/")[2],
      })
      .then((data) => {
        window.location.reload();
      })
      .catch((err) => alert(err));
  };
  */
  const allChecked = () => {
    if (checkAll) {
      setCheckAll(false);
      setBidsData(
        bidsData.map((item) => {
          item.select = false;
          return item;
        })
      );
    } else {
      setCheckAll(true);

      setBidsData(
        bidsData.map((item) => {
          item.select = true;
          return item;
        })
      );
    }
  };
  return (
    <>
      <section id="bid-card">
        <div className={style.contactBid}>
          <div className={style.contactNav}>
            <div>{t("aktiviteler")}</div>
            <div className={style.rigtNav}>
              {(user.department !== "hr" ||
                user.department !== "fd" ||
                user.department !== "pd" ||
                user.department !== "dms" ||
                user.department !== "mc") && (
                <Link to={`/create-offer/${locationId}`}>
                  <NewButton title={t("yeniAktiviteOlustur")} />
                </Link>
              )}
              {/*
              {(user.department === "admin" ||
                user.department === "ceo" ||
                user.department === "gm" ||
                user.department === "sd" ||
                user.department === "rd" ||
                user.department === "sm") && (
                <div className={style.delete} onClick={deleteCustomerByIds}>
                  {t("sil")}
                </div>
              )}
              */}
            </div>
          </div>
          <div className={style.contactBidİtemHead}>
            <input
              type="checkbox"
              style={{ marginLeft: 10 }}
              checked={checkAll}
              onClick={allChecked}
              onChange={(e) => {
                //console.log(e)
              }}
            />
            <div> {t("durum")}</div>
            <div> {t("aktiviteIsmi")}</div>
            <div> {t("malSahibi")}</div>
            <div> {t("aktiviteTarihi")}</div>
            <div> {t("aktiviteTipi")}</div>
            <div> {t("iliskiliProje")}</div>
            <div> {t("urunTipi")}</div>
            <div> {t("kdvHaric")}</div>
            <div> {t("indirimOrani")}</div>
            <div> {t("teklifAsamasi")}</div>
            <div style={{ flex: 2 }}>.</div>
          </div>
          <ContactBidItem
            setBidsData={setBidsData}
            bidsData={bidsData}
            contactData={contactData}
          />
        </div>
      </section>
    </>
  );
}

export default ContactBid;
