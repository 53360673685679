import style from "../CreateLead/createlead.module.scss";
import { BaseUrl } from "../../ApiConfig";
import { SubHeader } from "../../components";
import {
  GSMInput,
  TextInput,
  UserInput,
  Textarea,
} from "../../components/Forms/Inputs";
import axios from "axios";
import LeadPopup from "../CreateLead/LeadPopup";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ProjectSelection from "../../components/ui/projectSelection/ProjectSelection";
import { getAllProjects } from "../../lib/projectApi";

import { useTranslation } from "react-i18next";

function EditLead() {
  const { t } = useTranslation();

  const { id } = useParams();
  const user = useSelector((state) => state.user.userData);
  const [popup, setPopup] = useState(false);
  const navigate = useNavigate();
  const [errorText, setErrorText] = useState("");
  const [lead, setLead] = useState({
    owner: user.id,
    name: "",
    surname: "",
    phone: "",
    email: "",
    company: "",
    note: "",
    associatedProject: [],
    userType: "",
  });
  const [allProjects, setAllProjects] = useState([]);
  const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);

  useEffect(() => {
    if (!id || !user?.token) return;
    axios
      .get(`${BaseUrl}/api/lead/findone/${id}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        if (!res.data?.lead) return;
        const projectIds =
          res.data.lead.associatedProject.map((item) => item._id) || [];
        setLead((prevLead) => ({
          ...prevLead,
          owner: user.id || "",
          name: res.data.lead.name || "",
          surname: res.data.lead.surname || "",
          phone: res.data.lead.phone || "",
          email: res.data.lead.email || "",
          company: res.data.lead.company || "",
          note: res.data.lead.note || "",
          userType: res.data.lead.userType || "",
          associatedProject: projectIds,
        }));

        res.data.lead.associatedProject.forEach((item) => {
          lead.associatedProject.push(item._id);
        });

        setCheckedCheckboxes(projectIds);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 400) {
          navigate("/lead-list", { replace: true });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, user.token, user.id]);

  const onSubmit = () => {
    lead.associatedProject = checkedCheckboxes;
    const dep = user.department;
    if (
      checkedCheckboxes.length === 0 &&
      (dep === "ss" || dep === "sa" || dep === "sm")
    ) {
      setErrorText(t("projeSecinError"));
      setPopup(true);
      return;
    }
    if (lead.name.length <= 2) {
      setErrorText(t("isim2harfError"));
      setPopup(true);
      return;
    } else if (lead.surname.length <= 2) {
      setErrorText(t("soyisim2harfError"));
      setPopup(true);
      return;
    } else if (lead.phone.length === 0 && lead.email.length === 0) {
      setErrorText(t("emailTelefonError"));
      setPopup(true);
      return;
    } else {
      if (lead.email.length > 4 && !lead.email.includes("@")) {
        setErrorText(t("emailError"));
        setPopup(true);
        return;
      }
    }
    //console.log(lead);

    axios
      .put(`${BaseUrl}/api/lead/edit/${id}`, lead, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        setTimeout(() => {
          navigate("/lead-list", { replace: true });
        }, 2000);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  useEffect(() => {
    getAllProjects(user.token)
      .then((res) => {
        //console.log(res.data);
        setAllProjects(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user.token]);

  const popUpCloseHandler = () => {
    setPopup(false);
    setErrorText("");
  };

  return (
    <>
      {popup && (
        <LeadPopup closeClick={popUpCloseHandler} errorText={errorText} />
      )}
      <SubHeader
        editLead
        form
        onClick={onSubmit}
        path="/lead-list"
        newButtonName={t("degistir")}
      />
      <div className={style.createLeadContent}>
        <div className={style.leadForm}>
          <div className={style.leftLeadForm}>
            <TextInput
              value={lead.name}
              onChange={(text) => setLead({ ...lead, name: text })}
              title={t("isim")}
            />
            <TextInput
              value={lead.surname}
              onChange={(text) => setLead({ ...lead, surname: text })}
              title={t("soyisim")}
            />

            <GSMInput
              value={lead.phone}
              onChange={(text) => setLead({ ...lead, phone: text })}
              title={t("telefon")}
              placeholder={t("telefon")}
            />
            <TextInput
              value={lead.email}
              onChange={(text) => setLead({ ...lead, email: text })}
              title={t("email")}
              maxLength={"50"}
            />
            <TextInput
              value={lead.company}
              onChange={(text) => setLead({ ...lead, company: text })}
              title={t("sirket")}
            />
            {(user.department === "admin" ||
              user.department === "ceo" ||
              user.department === "gm" ||
              user.department === "mc" ||
              user.department === "dms" ||
              user.department === "sd" ||
              user.department === "rd") && (
              <ProjectSelection
                title={t("iliskilendirilenProje")}
                placeholder={
                  (checkedCheckboxes.length > 0
                    ? `(${checkedCheckboxes.length}) `
                    : "") + `${t("projeSeciniz")}`
                }
                allProjects={allProjects}
                setCheckedCheckboxes={setCheckedCheckboxes}
                checkedCheckboxes={checkedCheckboxes}
              />
            )}
          </div>
          <div className={style.rightLeadForm}>
            <TextInput
              disabled
              placeholder={user.userType}
              title={t("kanal")}
            />
            <UserInput
              value={`${user.name} ${user.surname}`}
              title={t("olusturanPersonel")}
            />
            <TextInput
              disabled
              placeholder={new Date().toLocaleString()}
              title={t("olusturulmaTarihi")}
            />
            <Textarea
              value={lead.note}
              title={t("not")}
              placeholder={t("not")}
              onChange={(text) =>
                setLead((prev) => {
                  return { ...prev, note: text };
                })
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default EditLead;
