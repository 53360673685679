import style from "./LeadPopup.module.scss";
import React, { useState, useEffect } from "react";
import { getUsersProjectPersonels } from "../../lib/userApi";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const UserRadioSection = ({
  selectedUser,
  setSelectedUser,
  associatedProject,
}) => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { token } = useSelector((state) => state.user.userData);

  useEffect(() => {
    /*
    if (assignedProject.length > 1) {
      return;
    }*/
    setIsLoading(true);
    const projectId = associatedProject[0]._id;
    getUsersProjectPersonels(token, projectId)
      .then((res) => {
        setUsers(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token, associatedProject]);

  return (
    <div className={style.radioSelectionContainer}>
      {/* {isLoading && <div className={style.loader}>Projeler Yükleniyor...</div>} */}
      {users.length === 0 && !isLoading ? (
        <div className={style.noPersonel}>{t("personelYok")}</div>
      ) : null}
      {users.map((user, index) => {
        return (
          <div key={index} className={style.radioSelection}>
            <input
              type="radio"
              name="user"
              id={user._id}
              value={user._id}
              checked={user._id === selectedUser._id}
              onChange={(e) => {
                setSelectedUser(user);
              }}
            />
            <label htmlFor={user._id}>{user.fullName}</label>
          </div>
        );
      })}
    </div>
  );
};

const LeadPopUp = ({
  closeClick,
  successClick,
  fullName,
  type,
  sendOwner,
  associatedProject,
}) => {
  const { t } = useTranslation();
  const [selectedUser, setSelectedUser] = useState({
    fullName: sendOwner.ownerName,
    _id: sendOwner.ownerId,
  });

  const sendText = t("gondermeTexti");

  const deleteText = t("leadSilmeTexti");

  const sendHandler = () => {
    if (type === "send") {
      sendOwner.ownerName = selectedUser.fullName;
      sendOwner.ownerId = selectedUser._id;
    }
    successClick();
  };

  return (
    <>
      <div className={style.cardPopupWrapper}>
        <div className={style.cardPopup}>
          <div className={style.popupHeader}>
            {type === "send" ? t("leadGondermeBaslik") : t("leadSilmeBaslik")}
          </div>
          <hr />
          {type === "send" && (
            <UserRadioSection
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
              associatedProject={associatedProject}
            />
          )}
          <div className={style.textcontainer}>
            <strong>{fullName}</strong>{" "}
            {type === "send" ? sendText : deleteText}
          </div>
          <div className={style.buttonContainer}>
            <button onClick={closeClick} className={style.closeModalPopup}>
              {t("vazgec")}
            </button>

            <button
              onClick={sendHandler}
              className={style.successModalPopup}
              disabled={
                type === "send" && selectedUser._id === "" ? true : false
              }
              style={{
                cursor:
                  type === "send" && selectedUser._id === ""
                    ? "not-allowed"
                    : "pointer",
                backgroundColor:
                  type === "send" && selectedUser._id === "" ? "#ccc" : null,
              }}
            >
              {type === "send"
                ? t("kontaklaraGonderBUtton")
                : t("leadSilmeButton")}
            </button>
          </div>
        </div>
      </div>
      <div className={style.shadowBox} onClick={closeClick}></div>
    </>
  );
};

export default LeadPopUp;
