import React from "react";
import style from "./editcontact.module.scss";
import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { SubHeader } from "../../components";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAllProjects } from "../../lib/projectApi";
import ProjectSelection from "../../components/ui/projectSelection/ProjectSelection";
import Popup from "../../containers/CreateLead/LeadPopup";
import {
  NumberInput,
  TextInput,
  UserInput,
  DropInput2,
  Textarea,
  DocumentInput,
  // DropInput,
  // NumberInput,
  GSMInput,
} from "../../components/Forms/Inputs";

import { useTranslation } from "react-i18next";

import { BaseUrl } from "../../ApiConfig";

function ContactEdit() {
  const { t, i18n } = useTranslation();
  const user = useSelector((state) => state.user.userData);
  const { contactSourceTR, contactSourceEN } = useSelector(
    (state) => state.system.contactSource
  );
  const [allProjects, setAllProjects] = useState([]);
  const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);
  // let userType = user.userType;

  const { id } = useParams();
  const navigate = useNavigate();
  const [documentFile, setDocumentFile] = useState();
  const [contactSource, setContactSource] = useState("");
  const [contactSourceText, setContactSourceText] = useState("");

  // const [budgetCheck, setBudgetCheck] = useState(false);
  //const [currencyCheck, setCurrencyCheck] = useState(false);
  const [projectCheck, setProjectCheck] = useState(false);

  const [lead, setLead] = useState({
    name: "",
    surname: "",
    phone: "",
    company: "",
    email: "",
    channel: "",
    owner: "",
    address: "",
    city: "",
    country: "",
    budget: "",
    currency: "",
    category: "",
    gender: "",
    gsm: "",
    type: "",
    age: "",
    createAtReal: "",
    shape: "",
    tckn: "",
    taxNo: "",
    emergencyName: "",
    emergencyPhone: "",
    corporateTitle: "",
    kvkkFileName: "",
    dataProcessing: "",
    kvkkDocumation: "",
    otherCountry: "",
    contactSource: "",
    contactStatus: "",
    associatedProject: [],
  });

  useEffect(() => {
    axios
      .get(`${BaseUrl}/api/contact/findone/${id}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        // console.log(res.data.contact);
        const projectIds =
          res.data.contact.associatedProject.map((item) => item._id) || [];
        setLead(res.data.contact);
        setLead((prev) => ({
          ...prev,
          associatedProject: projectIds,
        }));
        setContactSource(res.data.contact.contactSource);
        setContactSourceText(res?.data?.contact?.contactSourceText);
        setCheckedCheckboxes(projectIds);
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status === 400) {
          navigate("/contact-list", { replace: true });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    getAllProjects(user.token)
      .then((res) => {
        //console.log(res.data);
        setAllProjects(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user.token]);

  const onSubmit = () => {
    lead.associatedProject = checkedCheckboxes;

    if (checkedCheckboxes.length < 1) {
      return setProjectCheck(true);
    }

    /* 
    if ( lead.budget === "" || lead.budget === 0 ) {
      return setBudgetCheck(true);
    }

    if ( !lead.currency && lead.budget !== "" && lead.budget !== 0 ) {
      return setCurrencyCheck(true);
    }
    */

    let formData = new FormData();
    formData.append("documentContactKvkk", documentFile);
    formData.append("name", lead.name);
    formData.append("surname", lead.surname);
    formData.append("phone", lead.phone);
    formData.append("company", lead.company);
    formData.append("email", lead.email);
    formData.append("channel", lead.channel);
    formData.append("owner", lead.owner);
    formData.append("address", lead.address);
    formData.append("city", lead.city);
    formData.append("country", lead.country);
    formData.append("otherCountry", lead.otherCountry);
    formData.append("budget", lead.budget);
    formData.append("currency", lead.currency);
    formData.append("category", lead.category);
    formData.append("gender", lead.gender);
    formData.append("gsm", lead.gsm);
    formData.append("type", lead.type);
    formData.append("age", lead.age);
    formData.append("createAtReal", lead.createAtReal);
    formData.append("shape", lead.shape);
    formData.append("tckn", lead.tckn);
    formData.append("taxNo", lead.taxNo);
    formData.append("numberOfRooms", lead.numberOfRooms || 0);
    formData.append("view", lead.view);
    formData.append("contactSource", contactSource);
    formData.append("contactSourceText", contactSourceText);
    formData.append("emergencyName", lead.emergencyName);
    formData.append("emergencyPhone", lead.emergencyPhone);
    formData.append("corporateTitle", lead.corporateTitle);
    formData.append("dataProcessing", lead.dataProcessing);
    formData.append("contactStatus", lead.contactStatus);
    formData.append("associatedProject", checkedCheckboxes);
    axios
      .put(
        `${BaseUrl}/api/contact/edit/${id}`,

        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then((res) => {
        //console.log(res);
        setTimeout(() => {
          navigate("/contact-list", { replace: true });
        }, 2000);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
  const dropData = [
    {
      value: "Veri Sahibinin Alenileştirmesi",
      name: t("veriSahibi"),
    },
    {
      value: "Bir İş Akdi Kurulumu veya İfası",
      name: t("birIsAkdi"),
    },
    {
      value: "Veri Sorumlusu Hukuki Yükümlülükleri",
      name: t("veriSorumluluk"),
    },
    { value: "Açık Rıza Alınması", name: t("acikRizaAlinmasi") },
    { value: "KAPSAM DIŞI", name: t("kapsamDisi") },
  ];
  return (
    <>
      <SubHeader
        editContact
        form
        onClick={onSubmit}
        path="/contact-list"
        newButtonName={"Değiştir"}
      />
      <div className={style.editContactContent}>
        <div className={style.editContact}>
          <div className={style.leftContactEdit}>
            <DropInput2
              isDefault={lead.contactStatus}
              data={[
                { name: t("aktif"), value: "aktif" },
                { name: t("pasif"), value: "pasif" },
              ]}
              setDropValue={(text) => {
                setLead((prev) => ({
                  ...prev,
                  contactStatus: text,
                }));
              }}
              placeholder={t("kontakDurumu")}
              title={t("kontakDurumu")}
            />
            <TextInput
              value={lead.name || ""}
              onChange={(text) =>
                setLead((prev) => ({
                  ...prev,
                  name: text,
                }))
              }
              title={t("isim")}
              placeholder={t("isim")}
            />
            <TextInput
              value={lead.surname || ""}
              onChange={(text) =>
                setLead((prev) => ({
                  ...prev,
                  surname: text,
                }))
              }
              placeholder={t("soyisim")}
              title={t("soyisim")}
            />
            {(user.department === "admin" ||
              user.department === "ceo" ||
              user.department === "gm" ||
              user.department === "mc" ||
              user.department === "dms" ||
              user.department === "sd" ||
              user.department === "rd") && (
              <ProjectSelection
                title={t("ilgilendigiProje")}
                placeholder={
                  (checkedCheckboxes.length > 0
                    ? `(${checkedCheckboxes.length}) `
                    : "") + t("projeSeciniz")
                }
                allProjects={allProjects}
                setCheckedCheckboxes={setCheckedCheckboxes}
                checkedCheckboxes={checkedCheckboxes}
              />
            )}
            <TextInput
              value={lead.age || ""}
              onChange={(text) =>
                setLead((prev) => ({
                  ...prev,
                  age: text,
                }))
              }
              placeholder={t("yas")}
              title={t("yas")}
            />
            <DropInput2
              data={[
                { name: t("erkek"), value: "Erkek" },
                { name: t("kadin"), value: "Kadın" },
              ]}
              isDefault={lead.gender}
              setDropValue={(text) =>
                setLead((prev) => ({
                  ...prev,
                  gender: text,
                }))
              }
              placeholder={t("cinsiyet")}
              title={t("cinsiyet")}
            />
            <GSMInput
              value={lead.gsm || ""}
              onChange={(text) =>
                setLead((prev) => ({
                  ...prev,
                  gsm: text,
                }))
              }
              title={t("gsm")}
              placeholder={t("gsm")}
            />
            <GSMInput
              value={lead.phone || ""}
              onChange={(text) =>
                setLead((prev) => ({
                  ...prev,
                  phone: text,
                }))
              }
              title={t("telefon")}
              placeholder={t("telefon")}
            />

            <TextInput
              value={lead.email || ""}
              onChange={(text) =>
                setLead((prev) => ({
                  ...prev,
                  email: text,
                }))
              }
              title={t("email")}
              maxLength={"50"}
              placeholder={t("email")}
            />
            <TextInput
              value={lead.tckn || ""}
              onChange={(text) =>
                setLead((prev) => ({
                  ...prev,
                  tckn: text,
                }))
              }
              title={t("tcno")}
              placeholder={t("tcno")}
            />
            <TextInput
              // value={lead.tckn}
              onChange={(text) => {}}
              title={t("pasaportNumarasi")}
              placeholder={t("pasaportNumarasi")}
            />
            <TextInput
              value={lead.company || ""}
              onChange={(text) =>
                setLead((prev) => ({
                  ...prev,
                  company: text,
                }))
              }
              title={t("sirket")}
              placeholder={t("sirket")}
            />
            <TextInput
              value={lead.corporateTitle || ""}
              onChange={(text) =>
                setLead((prev) => ({
                  ...prev,
                  corporateTitle: text,
                }))
              }
              title={t("kurumsalUnvan")}
              placeholder={t("kurumsalUnvan")}
            />
            <Textarea
              title={t("adres")}
              placeholder={t("adres")}
              value={lead.address || ""}
              onChange={(text) =>
                setLead((prev) => ({
                  ...prev,
                  address: text,
                }))
              }
            />
            <NumberInput
              decimalScale={0}
              title={t("odaSayisi")}
              value={lead.numberOfRooms}
              placeholder={t("odaSayisi")}
              onChange={(text) =>
                setLead((prev) => ({
                  ...prev,
                  numberOfRooms: text,
                }))
              }
            />
            <TextInput
              value={lead.view || ""}
              title={t("manzara")}
              placeholder={t("manzara")}
              onChange={(text) =>
                setLead((prev) => ({
                  ...prev,
                  view: text,
                }))
              }
            />
          </div>
          <div className={style.rightContactEdit}>
            <TextInput
              value={lead.country || ""}
              onChange={(text) =>
                setLead((prev) => ({
                  ...prev,
                  country: text,
                }))
              }
              title={t("il")}
              placeholder={t("il")}
            />
            <TextInput
              value={lead.city || ""}
              onChange={(text) =>
                setLead((prev) => ({
                  ...prev,
                  city: text,
                }))
              }
              title={t("ilce")}
              placeholder={t("ilce")}
            />
            {/* {userType ? (
              userType === "Yurt Dışı" ? ( */}
            <TextInput
              value={lead.otherCountry || ""}
              onChange={(text) =>
                setLead((prev) => ({
                  ...prev,
                  otherCountry: text,
                }))
              }
              title={t("ulke")}
              placeholder={t("ulke")}
            />
            {/* ) : null
            ) : null} */}
            <TextInput
              value={lead.taxNo || ""}
              onChange={(text) =>
                setLead((prev) => ({
                  ...prev,
                  taxNo: text,
                }))
              }
              title={t("vergiNo")}
              placeholder={t("vergiNo")}
            />
            <DropInput2
              data={
                i18n.language === "tr"
                  ? contactSourceTR
                  : i18n.language === "en"
                  ? contactSourceEN
                  : null
              }
              setDropValue={(text) => {
                setLead((prev) => ({
                  ...prev,
                  contactSource: text,
                }));
                setContactSource(text);
              }}
              isDefault={lead.contactSource}
              placeholder={t("kontakKaynagi")}
              title={t("kontakKaynagi")}
            />
            {/*kontak kaynağında seçilenlerin dolumu */}
            {contactSource.length ? (
              <TextInput
                value={contactSourceText}
                onChange={(text) => {
                  setContactSourceText(text);
                }}
                title={
                  i18n.language === "tr"
                    ? contactSourceTR.find(
                        (item) => item.value === contactSource
                      )?.name
                    : i18n.language === "en"
                    ? contactSourceEN.find(
                        (item) => item.value === contactSource
                      )?.name
                    : null
                }
              />
            ) : null}

            <TextInput
              value={lead.emergencyName || ""}
              onChange={(text) =>
                setLead((prev) => ({
                  ...prev,
                  emergencyName: text,
                }))
              }
              title={t("acilDurumKisisi")}
              placeholder={t("acilDurumKisisi")}
            />

            <GSMInput
              value={lead.emergencyPhone || ""}
              onChange={(text) =>
                setLead((prev) => ({
                  ...prev,
                  emergencyPhone: text,
                }))
              }
              title={t("acilDurumKisisiTelefon")}
              placeholder={t("acilDurumKisisiTelefon")}
            />
            <DropInput2
              data={[
                { name: t("bireysel"), value: "Bireysel" },
                { name: t("kurumsal"), value: "Kurumsal" },
              ]}
              setDropValue={(text) =>
                setLead((prev) => ({
                  ...prev,
                  type: text,
                }))
              }
              isDefault={lead.type === "" ? "asda" : lead.type}
              placeholder={t("kontakTipi")}
              title={t("kontakTipi")}
            />

            <DropInput2
              data={[
                { name: t("musteri"), value: "Müşteri" },
                { name: t("isOrtagi"), value: "İş Ortağı" },
                { name: t("tedarikci"), value: "Tedarikçi" },
                { name: t("resmiGorevli"), value: "Resmi Görevli" },
                { name: t("diger"), value: "Diğer" },
              ]}
              isDefault={lead.category === "" ? "asda" : lead.category}
              setDropValue={(text) =>
                setLead((prev) => ({
                  ...prev,
                  category: text,
                }))
              }
              placeholder={t("kategorisi")}
              title={t("kategorisi")}
            />

            {lead.category === "Müşteri" ? (
              <DropInput2
                data={[
                  { name: t("alici"), value: "Alıcı" },
                  {
                    name: t("satici"),
                    value: "Satıcı",
                  },
                  { name: t("potensiyelAlici"), value: "Potansiyel Alıcı" },
                  { name: t("kiraci"), value: "Kiracı" },
                  { name: t("kiralayan"), value: "Kiralayan" },
                  { name: t("potansiyalKiraci"), value: "Potansiyel Kiracı" },
                  { name: t("diger"), value: "Diğer" },
                ]}
                isDefault={lead.shape === "" ? "asda" : lead.shape}
                setDropValue={(text) =>
                  setLead((prev) => ({
                    ...prev,
                    shape: text,
                  }))
                }
                placeholder={t("altKategorisi")}
                title={t("altKategorisi")}
              />
            ) : lead.category === "İş Ortağı" ? (
              <DropInput2
                data={[
                  { name: t("acente"), value: "Acente" },
                  { name: t("projeOfisi"), value: "Proje Ofisi" },
                  { name: t("mimarlikOfisi"), value: "Mimarlık Ofisi" },
                  { name: t("muteahhit"), value: "Müteahhit" },
                  { name: t("diger"), value: "Diğer" },
                ]}
                isDefault={lead.shape === "" ? "asda" : lead.shape}
                setDropValue={(text) =>
                  setLead((prev) => ({
                    ...prev,
                    shape: text,
                  }))
                }
                placeholder={t("altKategorisi")}
                title={t("altKategorisi")}
              />
            ) : lead.category === "Resmi Görevli" ? (
              <DropInput2
                data={[
                  {
                    name: t("tapuDairesiYetkilisi"),
                    value: "Tapu Dairesi Yetkilisi",
                  },
                  { name: t("belediyeYetkilisi"), value: "Belediye Yetkilisi" },
                  { name: t("kontrolor"), value: "Kontrolör" },
                  { name: t("diger"), value: "Diğer" },
                ]}
                isDefault={lead.shape === "" ? "asda" : lead.shape}
                setDropValue={(text) =>
                  setLead((prev) => ({
                    ...prev,
                    shape: text,
                  }))
                }
                placeholder={t("altKategorisi")}
                title={t("altKategorisi")}
              />
            ) : (
              <DropInput2
                placeholder={t("altKategorisi")}
                title={t("altKategorisi")}
              />
            )}
            <DropInput2
              data={[
                { name: "500K", value: 500000 },
                { name: "750K", value: 750000 },
                { name: "1M", value: 1000000 },
                { name: "1.25M", value: 1250000 },
                { name: "1.5M", value: 1500000 },
                { name: "1.75M", value: 1750000 },
                { name: "2M", value: 2000000 },
                { name: "2.25M", value: 2250000 },
                { name: "2.5M", value: 2500000 },
                { name: "2.75M", value: 2750000 },
                { name: "3M", value: 3000000 },
                { name: "3.25M", value: 3250000 },
                { name: "3.5M", value: 3500000 },
                { name: "3.75M", value: 3750000 },
                { name: "4M", value: 4000000 },
                { name: "4.25M", value: 4250000 },
                { name: "4.5M", value: 4500000 },
                { name: "4.75M", value: 4750000 },
                { name: "5M", value: 5000000 },
                { name: "6M", value: 6000000 },
                { name: "7M", value: 7000000 },
                { name: "8M", value: 8000000 },
                { name: "9M", value: 9000000 },
                { name: "10M", value: 10000000 },
                { name: "20M", value: 20000000 },
                { name: "30M", value: 30000000 },
                { name: "40M", value: 40000000 },
                { name: "50M", value: 50000000 },
              ]}
              isDefault={lead.budget === 0 ? "asda" : lead.budget}
              setDropValue={(text) =>
                setLead((prev) => ({
                  ...prev,
                  budget: text,
                }))
              }
              placeholder={t("butce")}
              title={t("butce")}
            />
            <DropInput2
              data={[
                { name: "TRY", value: "TRY" },
                { name: "USD", value: "USD" },
                { name: "EUR", value: "EUR" },
                { name: "GBP", value: "GBP" },
              ]}
              isDefault={lead.currency === "" ? "asda" : lead.currency}
              setDropValue={(text) =>
                setLead((prev) => ({
                  ...prev,
                  currency: text,
                }))
              }
              placeholder={t("currency")}
              title={t("currency")}
            />
            <TextInput
              disabled
              value={lead.channel || ""}
              title={t("kanal")}
              placeholder={t("manüel")}
              onChange={(text) =>
                setLead((prev) => ({
                  ...prev,
                  channel: text,
                }))
              }
            />

            <UserInput
              value={lead.owner || ""}
              onChange={(text) =>
                setLead((prev) => ({
                  ...prev,
                  owner: text,
                }))
              }
              title={t("olusturanPersonel")}
            />
            <TextInput
              value={lead.createAtReal || ""}
              title={t("olusturulmaTarihi")}
              placeholder={lead.createAtReal}
              disabled
            />
            {/* <DropInput
                placeholder={t("veriIslemeGerekcesi")}
                title={t("veriIslemeGerekcesiTitle")}
                setDropValue={(text) =>
                  setLead((prev) => {
                    return { ...prev, dataProcessing: text };
                  })
                }
                // placeholder={lead.dataProcessing}
                // isDefault={lead.dataProcessing}
                // setDropValue={setDropValue}
                dropValue={lead.dataProcessing}
                isDefault={lead.dataProcessing === "" ? "asda" : lead.dataProcessing}
                data={dropData}
              /> */}
            <DropInput2
              data={dropData}
              isDefault={
                lead.dataProcessing === "" ? "asda" : lead.dataProcessing
              }
              setDropValue={(text) =>
                setLead((prev) => ({
                  ...prev,
                  dataProcessing: text,
                }))
              }
              placeholder={t("veriIslemeGerekcesi")}
              title={t("veriIslemeGerekcesiTitle")}
            />

            {lead.dataProcessing === "Veri Sahibinin Alenileştirmesi" ||
            lead.dataProcessing === "Açık Rıza Alınması" ? (
              <div style={{ marginLeft: "-1.7rem" }}>
                <DocumentInput
                  placeholder={lead.kvkkFileName}
                  setDocumentFile={setDocumentFile}
                  documentFile={documentFile}
                />
                <Link
                  target="_blank"
                  key={lead.kvkkFileName}
                  className={style.editContactDownloadLink}
                  to={`/public/uploads/${lead.kvkkFileName}`}
                >
                  {t("belgeIndir")}
                </Link>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {projectCheck ? (
        <Popup
          errorText={t("kontakIlgilendigiProje")}
          title={t("yeniKontakPopupTitle")}
          closeClick={() => setProjectCheck(false)}
        />
      ) : null}
      {/* 
      {budgetCheck ? (
        <Popup
          errorText={t("kontakBudget")}
          title={t("kontakBudget")}
          closeClick={() => setBudgetCheck(false)}
        />
      ) : null}
      {currencyCheck ? (
        <Popup
          errorText={t("kontakCurrency")}
          title={t("kontakCurrency")}
          closeClick={() => setCurrencyCheck(false)}
        />
      ) : null}
      */}
    </>
  );
}

export default ContactEdit;
