import React, { useState } from "react";
import style from "./elements.module.scss";
import PaymentPlanItem from "./PaymentPlanItem";
import axios from "axios";
import Popup from "../../containers/CreateLead/LeadPopup";
import { BaseUrl } from "../../ApiConfig";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const PaymentPlanSetting = ({
  paymentPlans,
  setPaymentPlans,
  projectId,
  project,
  fin,
  setFin,
}) => {
  const { t } = useTranslation();

  const user = useSelector((state) => state.user.userData);
  const [popup, setPopup] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [fakeInput, setFakeInput] = useState(false);
  const [changeButton, setChangeButton] = useState(false);
  const [errorHandleText, setErrorHandleText] = useState("");
  const [errorHandlePopUp, setErrorHandlePopUp] = useState(false);

  const [paymentPlanSetting, setPaymentPlanSetting] = useState({
    isCash: false,
    cashDiscountRate: "",
    installmentCount: "12",
    upfrontPaymentRate: "35",
    specialPaymentRate: "15",
    specialPaymentAmount: "",
    specialPaymentCount: "1",
    specialPaymentType: "none",
    name: "",
  });

  const _onSubmit = () => {
    const userType = user.userType;
    console.log(paymentPlanSetting);
    setPaymentPlanSetting({
      ...paymentPlanSetting,
      userType: userType,
    });
    axios
      .post(
        `${BaseUrl}/api/paymetPlanSetting/add/${projectId}`,
        paymentPlanSetting,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then((res) => {
        setPaymentPlanSetting({
          ...paymentPlanSetting,
          isCash: false,
          cashDiscountRate: "",
          installmentCount: "12",
          upfrontPaymentRate: "35",
          specialPaymentRate: "15",
          specialPaymentAmount: "",
          specialPaymentCount: "1",
          specialPaymentType: "none",
          name: "",
        });
      })
      .catch((error) => {
        console.log(error.response);
      })
      .finally(() => {
        setFin(fin + 1);
      });
  };
  const _onDelete = () => {
    axios
      .delete(`${BaseUrl}/api/note/delete/${selectedId}/${projectId}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        setPopup(false);
      })
      .catch((error) => {
        console.log(error.response);
      })
      .finally(() => {
        setFin(fin + 1);
      });
  };
  const _onEdit = () => {
    const userType = user.userType;
    if (paymentPlanSetting.name.trim().length < 5) {
      setErrorHandleText("");
      setErrorHandleText(t("odemePlaniBosGecilemez"));
      setErrorHandlePopUp(true);
      return;
    }
    setPaymentPlanSetting({ ...paymentPlanSetting, userType: userType });
    axios
      .put(
        `${BaseUrl}/api/paymetPlanSetting/put/${selectedId}`,
        paymentPlanSetting,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then((res) => {
        setPaymentPlanSetting({ ...paymentPlanSetting, name: "" });
        setChangeButton(false);
      })

      .catch((error) => {
        setPaymentPlanSetting({ ...paymentPlanSetting, name: "" });
        setChangeButton(false);

        console.log(error.response);
      })
      .finally(() => {
        setFin(fin + 1);
      });
  };

  return (
    <>
      {popup ? (
        <Popup
          successClick={_onDelete}
          setPopup={setPopup}
          errorText="Bu ödeme planını silmek istediğinizden emin misiniz?"
          title="ÖDEME PLANINI SİL"
          type="delete"
          closeClick={() => setPopup(false)}
        />
      ) : null}
      <section id="detail-note">
        <div className={style.projectPayment}>
          <div className={style.projects}>{t("odemePlani")}</div>
          {fakeInput ? (
            <>
              <div className={style.addProject}>
                <label>
                  <input
                    placeholder={t("odemePlaniAdi")}
                    type="text"
                    onChange={(e) =>
                      setPaymentPlanSetting((prev) => ({
                        ...prev,
                        name: e.target.value,
                      }))
                    }
                    value={paymentPlanSetting.name}
                  />
                </label>
                <div className={style.action}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex" }}>
                      <h2>{t("pesinOdememi")}</h2>
                      <input
                        type="checkbox"
                        value={paymentPlanSetting.isCash}
                        checked={paymentPlanSetting.isCash}
                        onChange={(e) => {
                          setPaymentPlanSetting((prev) => ({
                            ...prev,
                            isCash: e.target.checked,
                          }));
                        }}
                      />
                    </div>
                    {paymentPlanSetting.isCash ? (
                      <div style={{ display: "flex" }}>
                        <h2>{t("pesinOdemeIndirimOrani")}</h2>
                        <input
                          type="text"
                          style={{ border: "1px solid black" }}
                          value={paymentPlanSetting.cashDiscountRate}
                          onChange={(e) => {
                            setPaymentPlanSetting((prev) => ({
                              ...prev,
                              cashDiscountRate: e.target.value,
                            }));
                          }}
                        />
                      </div>
                    ) : (
                      <>
                        <div style={{ display: "flex" }}>
                          <h2>{t("taksitSayisi")}</h2>
                          <input
                            type="text"
                            style={{ border: "1px solid black", width: "50%" }}
                            value={paymentPlanSetting.installmentCount}
                            onChange={(e) => {
                              setPaymentPlanSetting((prev) => ({
                                ...prev,
                                installmentCount: e.target.value,
                              }));
                            }}
                          />
                        </div>

                        <div style={{ display: "flex" }}>
                          <h2>{t("pesinOdemeOrani")}</h2>
                          <input
                            type="text"
                            style={{ border: "1px solid black", width: "50%" }}
                            value={paymentPlanSetting.upfrontPaymentRate}
                            onChange={(e) => {
                              setPaymentPlanSetting((prev) => ({
                                ...prev,
                                upfrontPaymentRate: e.target.value,
                              }));
                            }}
                          />
                        </div>
                        <div style={{ display: "flex" }}>
                          <h2>{t("araOdemeTipi")}</h2>
                          <select
                            style={{
                              border: "1px solid black",
                              width: "50%",
                            }}
                            value={paymentPlanSetting.specialPaymentType}
                            onChange={(e) => {
                              setPaymentPlanSetting((prev) => ({
                                ...prev,
                                specialPaymentType: e.target.value,
                              }));
                            }}
                          >
                            <option value="none">{t("yok")}</option>
                            <option value="percentage">
                              {t("araOdemeTipiYuzde")}
                            </option>
                            <option value="fixed">
                              {t("araOdemeTipiSabit")}
                            </option>
                          </select>
                        </div>
                        {paymentPlanSetting.specialPaymentType ===
                        "percentage" ? (
                          <div style={{ display: "flex" }}>
                            <h2>{t("araOdemeOrani")}</h2>
                            <input
                              type="text"
                              style={{
                                border: "1px solid black",
                                width: "50%",
                              }}
                              value={paymentPlanSetting.specialPaymentRate}
                              onChange={(e) => {
                                setPaymentPlanSetting((prev) => ({
                                  ...prev,
                                  specialPaymentRate: e.target.value,
                                }));
                              }}
                            />
                          </div>
                        ) : paymentPlanSetting.specialPaymentType ===
                          "fixed" ? (
                          <div style={{ display: "flex" }}>
                            <h2>{t("araOdemeTutari")}</h2>
                            <input
                              type="text"
                              style={{
                                border: "1px solid black",
                                width: "50%",
                              }}
                              value={paymentPlanSetting.specialPaymentAmount}
                              onChange={(e) => {
                                setPaymentPlanSetting((prev) => ({
                                  ...prev,
                                  specialPaymentAmount: e.target.value,
                                }));
                              }}
                            />
                          </div>
                        ) : null}
                        {paymentPlanSetting.specialPaymentType.includes(
                          "percentage",
                          "fixed"
                        ) ? (
                          <div style={{ display: "flex" }}>
                            <h2>{t("araOdemeSayisi")}</h2>
                            <input
                              type="text"
                              style={{
                                border: "1px solid black",
                                width: "50%",
                              }}
                              value={paymentPlanSetting.specialPaymentCount}
                              onChange={(e) => {
                                setPaymentPlanSetting((prev) => ({
                                  ...prev,
                                  specialPaymentCount: e.target.value,
                                }));
                              }}
                            />
                          </div>
                        ) : null}
                      </>
                    )}
                  </div>
                  <div>
                    <button
                      className={style.close}
                      onClick={() => {
                        setFakeInput(false);
                        setPaymentPlanSetting({
                          isCash: false,
                          cashDiscountRate: "",
                          installmentCount: "12",
                          upfrontPaymentRate: "35",
                          specialPaymentRate: "15",
                          specialPaymentAmount: "",
                          specialPaymentCount: "",
                          specialPaymentType: "percentage",
                          name: "",
                        });
                        setChangeButton(false);
                      }}
                    >
                      {t("iptal")}
                    </button>
                    {changeButton ? (
                      <button
                        onClick={_onEdit}
                        className={
                          paymentPlanSetting.name.length >= 5
                            ? style.save
                            : style.close
                        }
                      >
                        {t("degistir")}
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          if (paymentPlanSetting.name.trim().length < 5) {
                            setErrorHandleText(t("odemePlaniBosGecilemez"));
                            setErrorHandlePopUp(true);
                            return;
                          }
                          _onSubmit();
                        }}
                        className={
                          paymentPlanSetting.name.length >= 5
                            ? style.save
                            : style.close
                        }
                      >
                        {t("ekle")}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                className={style.fakeNotes}
                onClick={() => setFakeInput(true)}
              >
                <label>
                  <input
                    style={{ color: "grey" }}
                    defaultValue={t("odemePlaniEkle")}
                    type="text"
                    placeholder={t("odemePlaniEkle")}
                  />
                </label>
              </div>
            </>
          )}
          <PaymentPlanItem
            setFakeInput={setFakeInput}
            paymentPlans={paymentPlans}
            setPaymentPlanSetting={setPaymentPlanSetting}
            setPopup={setPopup}
            setSelectedId={setSelectedId}
            project={project}
            setChangeButton={setChangeButton}
          />
          {errorHandlePopUp ? (
            <Popup
              title={t("odemePlani")}
              errorText={errorHandleText}
              closeClick={() => setErrorHandlePopUp(false)}
            />
          ) : null}
        </div>
      </section>
    </>
  );
};

export default PaymentPlanSetting;
