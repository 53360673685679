import style from "./contactdetail.module.scss";
import {
  SubHeader,
  SideNav,
  ContactCard,
  ContactBid,
  ContactPayment,
  ContactNote,
  ContactUploadFile,
  ContactLog,
} from "../../components";
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { BaseUrl } from "../../ApiConfig";
import axios from "axios";

const ContactDetail = () => {
  const user = useSelector((state) => state.user.userData);
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [bids, setBids] = useState();
  const [notes, setNotes] = useState();
  const [fin, setFin] = useState(0);
  const [lengths, setLengths] = useState({
    bids: 0,
    notes: 0,
    documents: 0,
  });
  const [document, setDocuments] = useState();
  const { id } = useParams();
  useEffect(() => {
    axios
      .get(`${BaseUrl}/api/contact/findone/${id}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        setData(res.data.contact);
        setLengths({
          ...lengths,
          bids: res.data.bidsLength,
          notes: res.data.noteLength,
          documents: res.data.documentLength,
        });
        const bids = res.data.contact.bids;
        const notes = res.data.contact.notes;
        const documents = res.data.contact.documents;
        setDocuments(
          documents.map((d) => {
            return {
              select: false,
              createAt: d.createAt,
              fileName: d.fileName,
              fullFileName: d.fullFileName,
              ownerFile: d.ownerFile,
              size: d.size,
              uid: d.uid,
              id: d._id,
              isGeneral: d.isGeneral,
              bids: d.bids,
            };
          })
        );
        //console.log(notes);
        setNotes(notes);
        setBids(
          bids.map((d) => {
            return {
              select: false,
              //  contactId: res.data.contact._id,
              id: d._id,
              bidName: d.bidName,
              bidShape: d.bidShape,
              closingDate: d.closingDate,
              createAtReal: d.createdAt,
              estimateDate: d.estimateDate,
              inventoryName: d.inventoryName,
              projeName: d.projeName,
              bidAmount: d.bidAmount,
              bidStage: d.bidStage,
              currency: d.currency,
              ownerInventory: d.ownerInventory,
              discountRate: d.discountRate,
              inventoryId: d.inventoryId,
              // emergencyName: d.emergencyName,
              // emergencyPhone: d.emergencyPhone,
              advancePayment: d.advancePayment,
              advancePaymentDate: d.advancePaymentDate,
              downPaymnet: d.downPaymnet,
              downPaymnetDate: d.downPaymnetDate,
              termDownPaymnet: d.termDownPaymnet,
              termDownPaymnetDate: d.termDownPaymnetDate,
              paymentType: d.paymentType,
              bankCredit: d.bankCredit,
              bankCreditDate: d.bankCreditDate,
              interestAmount: d.interestAmount,
              deliveryTime: d.deliveryTime,
              bidAmountWithVat: d.bidAmountWithVat,
            };
          })
        );
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.status === 400) {
          navigate("/contact-list", { replace: true });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, fin]);

  return (
    <div className={style.contactDetail}>
      <SubHeader contactDetail contactDetailData={data} />
      <div className={style.content}>
        <SideNav href={"/contact-list"} lenghts={lengths} contactDetail />
        <div className={style.info}>
          <ContactCard data={data} />
          <ContactBid
            bidsData={bids}
            setBidsData={setBids}
            contactData={data}
          />
          <ContactPayment contactId={id} user={user} />
          <ContactNote
            setNotes={setNotes}
            contactId={id}
            notes={notes}
            fin={fin}
            setFin={setFin}
            bids={bids}
          />
          <ContactUploadFile
            bids={bids}
            document={document}
            setDocuments={setDocuments}
            setFin={setFin}
            fin={fin}
          />
          <ContactLog contactId={id} user={user} />
          <div style={{ marginBottom: "5em" }}></div>
        </div>
      </div>
    </div>
  );
};

export default ContactDetail;
